import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { signUpValidator } from "../validators/Validators";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
/** Internal Imports */
import { signUpRequest } from "../store/user/user.action";
import { userSelector } from "../store/user/user.model";
import "../assets/css/bootstrap.min.css";
import "../assets/css/menu.css";
import "../assets/css/style.css";
import "../assets/css/vendors.css";
import LoadingButton from "@mui/lab/LoadingButton";

const Signup = () => {
  /** React Component Functions */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** Selectors */
  const { isLoading, signUpLoad, signupStatus } = useSelector(userSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(signUpValidator),
  });

  const hanldeSignup = (formData) => {
    delete formData.confirm_password;
    dispatch(signUpRequest(formData));
  };

  /** useEffects */

  useEffect(() => {
    if (signupStatus) {
      reset();
    }
  }, [signupStatus]);

  return (
    <>
      {/* <!-- /menu -->	 */}
      <div class="container-fluid">
        <div class="row row-height">
          <div class="col-xl-4 col-lg-4 content-left">
            <div class="content-left-wrapper">
              {/* <!-- <a href="index.html" id="logo"><img src="img/logo.png" alt="" width="45" height="45"></a> --> */}
              <div id="social">
                <ul>
                  <li>
                    <a href="#0">
                      <i class="bi bi-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-twitter-x"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-tiktok"></i>
                    </a>
                  </li>
                </ul>
              </div>
              {/* <!-- /social --> */}
              <div class="login_section">
                <h2 class="section_title">Register</h2>
                <form
                  className="tooltip-end-bottom"
                  onSubmit={handleSubmit(hanldeSignup)}
                >
                  <div class="form-group">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      class="form-control required"
                      placeholder="Name"
                      {...register("name")}
                    />
                    {errors?.name?.message && (
                      <div>
                        <p className="error text-danger">
                          {errors?.name?.message}
                        </p>
                      </div>
                    )}
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      name="phone_no"
                      id="phone_no"
                      class="form-control required"
                      placeholder="Phone Number"
                      {...register("phone_no")}
                    />
                    {errors?.phone_no?.message && (
                      <div>
                        <p className="error text-danger">
                          {errors?.phone_no?.message}
                        </p>
                      </div>
                    )}
                  </div>

                  <div class="form-group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      class="form-control required"
                      placeholder="Email"
                      {...register("email")}
                    />
                    {errors?.email?.message && (
                      <div>
                        <p className="error text-danger">
                          {errors?.email?.message}
                        </p>
                      </div>
                    )}
                  </div>

                  <div class="form-group">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      class="form-control required"
                      placeholder="Password"
                      {...register("password")}
                    />
                    {errors?.password?.message && (
                      <div>
                        <p className="error text-danger">
                          {errors?.password?.message}
                        </p>
                      </div>
                    )}
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      name="confirm_password"
                      id="confirm_password"
                      class="form-control required"
                      placeholder="Confirm Password"
                      {...register("confirm_password")}
                    />
                    {errors?.confirm_password?.message && (
                      <div>
                        <p className="error text-danger">
                          {errors?.confirm_password?.message}
                        </p>
                      </div>
                    )}
                  </div>
                  <div class="forgot">
                    <p>
                      By clicking "Create account" below, you agree to the
                      Ancestry{" "}
                      <u onClick={() => navigate("")}>Terms and Conditions</u>{" "}
                      and <u onClick={() => navigate("")}>Privacy Statement</u>{" "}
                      and agree Ancestry may contact you via email about their
                      products and services. You can unsubscribe or customize
                      your email settings at any time.
                    </p>
                  </div>
                  <div class="submit_btn">
                    {/* <button class="btn_1 rounded yellow" type="submit">
                      Create Account
                    </button> */}
                    {/* <LoadingButton title="Create Account" /> */}
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      className="btn_1 rounded yellow"
                      loading={signUpLoad}
                    >
                      Create Account
                    </LoadingButton>
                  </div>
                </form>

                <div class="sign_text">
                  <p>
                    Already have an account?
                    <div onClick={() => navigate("/signin")}>
                      <u>Sign In</u>
                    </div>
                  </p>
                </div>
              </div>
              <div class="copy">© 2024 Nanyar</div>
            </div>
            {/* <!-- /content-left-wrapper --> */}
          </div>
          {/* <!-- /content-left --> */}
          <div class="col-xl-8 col-lg-8 content-right bg_ad_2" id="start">
            <div class="background"></div>
          </div>
          {/* <!-- /content-right--> */}
        </div>
        {/* <!-- /row--> */}
      </div>
      {/* <!-- /container-fluid --> */}
    </>
  );
};

export default Signup;
