import * as Yup from "yup";
import { selectDefault } from "../utils/Constants";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const signInValidator = Yup.object().shape({
  email: Yup.string().trim().required("Email is Required"),
  password: Yup.string().required("Password is required"),
});

export const signUpValidator = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  email: Yup.string().required("Email is Required"),
  phone_no: Yup.string().required("Phone no is Required"),
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password"),
});

export const addMemberValidator = Yup.object().shape({
  name: Yup.string(),
  dob: Yup.date()
    .max(new Date(), "Date of Birth cannot be in the future")
    .required("Date of Birth is required"),
  dod: Yup.date()
    .max(new Date(), "Date of Death cannot be in the future")
    .when("dob", (dob, schema) => {
      return schema.min(dob, "Date of Death must be after Date of Birth");
    }),

  relation_id: Yup.mixed().test(
    "is-number-or-specific-string",
    "Select parent id",
    function (value) {
      if (value === selectDefault) {
        return this.createError({ message: "Select parent id" });
      } else {
        const values = parseInt(value);

        if (typeof values === "number") {
          return true;
        }
        return this.createError({ message: "Value must be a number" });
      }
    }
  ),
  // node_id: Yup.mixed().test(
  //   "is-number-or-specific-string",
  //   "Select parent id",
  //   function (value) {
  //     alert(value);
  //     if (value === selectDefault) {
  //       return this.createError({ message: "Select parent id" });
  //     } else {
  //       const values = parseInt(value);

  //       if (typeof values === "number") {
  //         return true;
  //       }
  //       return this.createError({ message: "Value must be a number" });
  //     }
  //   }
  // ),
  // parent_id:  Yup.mixed().test(
  //   "is-number-or-specific-string",
  //   "Select parent id",
  //   function (value) {
  //     alert(value);
  //     if (value === selectDefault) {
  //       return this.createError({ message: "Select parent id" });
  //     } else {
  //       const values = parseInt(value);

  //       if (typeof values === "number") {
  //         return true;
  //       }
  //       return this.createError({ message: "Value must be a number" });
  //     }
  //   }
  // ),
  gender: Yup.string(),
  locations: Yup.number().required("Loction Required"),
});

export const personalDataValidator = Yup.object({
  dob: Yup.date().required("Dob Required"),
  gender: Yup.string().required("Gender Required"),
  locations: Yup.number().required("Loction Required"),
});

export const userProfileUpdateValidator = Yup.object({
  name: Yup.string().required("Name is Required"),
  email: Yup.string().required("Email is Required"),
  phone_no: Yup.string().required("Phone no is Required"),
  dob: Yup.date().required("DOB Required"),
  gender: Yup.string().required("Gender Required"),
  locations: Yup.number().required("Loction Required"),
});

export const addEventValidator = Yup.object({
  service_id: Yup.number().required("Service Required"),
  phone_no: Yup.string().required("Phone no is Required"),
  description: Yup.string().notRequired(),
  secondary_phone: Yup.string().notRequired(),
  locations: Yup.number().required("Loction Required"),
  date: Yup.date().required("DOB Required"),
});
export const addServiceValidator = Yup.object({
  name: Yup.string().required("Name is Required"),
  description: Yup.string().notRequired(),
});

export const serviceValidator = Yup.object({
  name: Yup.string().required("Name is Required"),
});
