import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Swiper, SwiperSlide, } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import birthdaypic from "../assets/img/birthdaypic.jpg";
// import birthday from '../assets/img/birthday.jpg';
import birthday from "../assets/img/birthday.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import {
  faGift,
  faBell,
  faEnvelopesBulk,
  faUmbrellaBeach,
  faUtensils,
  faMap,
  faDoorClosed,
  faTree,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import marry from "../assets/img/marry.jpg";
import ancestor from "../assets/img/peoplefind.jpg";
import "../assets/css/homepage.css";
import "../assets/css/responsive.css";
import Header from "./Header";
import Footer from "./Footer";
import { BiStreetView } from "react-icons/bi";
import { LuFiles } from "react-icons/lu";
import { MdConnectWithoutContact } from "react-icons/md";


function Home() {
  return (
    <>
      <div className="header-after">
        <Header />
      </div>
      <section className="banner-sec">
        <Container>
          <div className="banner-content">
            <span>Explore Your Roots. Discover Your Heritage.</span>
            <h1>
              Uncover Your <br /> Family's History
            </h1>
          </div>
        </Container>
        <div className="banner-bg"></div>
      </section>
      <section className="marry-gifts-sec birthday-sec">
        <Container>
          <Row className="flex-direc">
            <Col md={6} lg={6} sm={12} className="width-100">
              <Row>
                <Col md={6} lg={6}>
                  <div className="detail-text-card margin-top">
                    <BiStreetView
                      className="detail-icon fs-1"
                    />
                    <h4>Explore Your Family Tree</h4>
                    <p>
                      Trace your lineage and uncover the branches of your family
                      tree with our comprehensive tools.
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="detail-text-card">
                    <LuFiles className="detail-icon fs-1" />
                    <h4>Discover Historical Records</h4>
                    <p>
                      Access a vast collection of historical documents to learn
                      more about your ancestors' lives and stories.
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="detail-text-card margin-lefttop mar-1">
                    <MdConnectWithoutContact
                      className="detail-icon fs-1"
                    />
                    <h4>Connect with Relatives</h4>
                    <p>
                      Find and connect with distant relatives to share memories
                      and build a richer family history.
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="detail-img-card margin-plustop">
                    <img src={ancestor} />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6} lg={6} sm={12} className="align-self width-100">
              <div className="birthday-text-style marrage-gift">
                <span>Discover Your Family's Roots</span>
                <h2>Uncover the History of Your Ancestors with Us</h2>
                <p>
                  Delve into your family's rich history and uncover the stories
                  of your ancestors. Our Ancestor Discovery feature helps you
                  trace your lineage, connect with your heritage, and understand
                  the lives of those who came before you. Embark on a journey
                  through time and discover the fascinating tales of your
                  forebears, creating a deeper connection with your past.
                </p>
                <Button>Know More</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="birthday-sec">
        <Container>
          <Row>
            <Col md={6} lg={6} sm={12} className="align-self width-100">
              <div className="birthday-text-style birth-gift">
                <span>Never Miss a Birthday Again</span>
                <h2>Celebrate Your Loved Ones' Birthdays with Us</h2>
                <p>
                  we understand the importance of family and the joy that comes
                  with celebrating birthdays. Our Birthday Reminders feature
                  ensures you never miss an important day, keeping you connected
                  with your loved ones across generations.
                </p>
                <Button>Know More</Button>
              </div>
            </Col>
            <Col md={6} lg={6} sm={12} className="width-100">
              <Row>
                <Col md={6} lg={6}>
                  <div className="detail-img-card margin-top">
                    <img src={birthdaypic} />
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="detail-text-card">
                    <FontAwesomeIcon className="detail-icon" icon={faGift} />
                    <h4>Send Thoughtful Gifts</h4>
                    <p>
                      Send personalized heritage gifts for birthdays through our
                      website
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="detail-text-card margin-lefttop">
                    <FontAwesomeIcon className="detail-icon" icon={faBell} />
                    <h4>Automatic Reminders</h4>
                    <p>
                      Receive timely notifications about upcoming family
                      birthdays
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="detail-img-card margin-plustop">
                    <img src={birthday} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="marry-gifts-sec">
        <Container>
          <Row className="flex-direc">
            <Col md={6} lg={6} sm={12} className="width-100">
              <Row>
                <Col md={6} lg={6}>
                  <div className="detail-text-card margin-top">
                    <FontAwesomeIcon
                      className="detail-icon"
                      icon={faEnvelopesBulk}
                    />
                    <h4>Elegant Invitation Designs</h4>
                    <p>
                      tailor-made invitations that add a touch of sophistication
                      to any event.
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="detail-text-card">
                    <FontAwesomeIcon className="detail-icon" icon={faGift} />
                    <h4>Send Thoughtful Gifts</h4>
                    <p>
                      Send personalized heritage gifts for birthdays through our
                      website
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="detail-text-card margin-lefttop mar-1">
                    <FontAwesomeIcon
                      className="detail-icon"
                      icon={faUmbrellaBeach}
                    />
                    <h4>Dream Vacation Planning</h4>
                    <p>
                      Expertly curated travel itineraries that turn your
                      vacation dreams into reality.
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="detail-img-card margin-plustop">
                    <img src={marry} />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6} lg={6} sm={12} className="align-self width-100">
              <div className="birthday-text-style marrage-gift">
                <span>Thoughtful Marriage Gifts</span>
                <h2>Celebrate your Love and Heritage with Us</h2>
                <p>
                  Set the tone for your special day with exquisite wedding
                  invitations that reflect your shared heritage and story.
                  Choose from a variety of customizable designs that beautifully
                  incorporate your family history.
                </p>
                <Button>Know More</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="death-sec">
        <Container>
          <Row>
            <Col md={6} lg={6} sm={12} className="align-self width-100">
              <div className="birthday-text-style birth-gift">
                <span>with unwavering respect</span>
                <h2>Honoring Loved Ones with Care and Respect</h2>
                <p>
                  Ensure the resting places of your loved ones are kept in
                  pristine condition with our professional cemetery cleaning
                  services. Our dedicated team treats each site with the utmost
                  care and respect, preserving the sanctity and beauty of the
                  gravesites.
                </p>
                <Button>Know More</Button>
              </div>
            </Col>
            <Col md={6} lg={6} sm={12} className="width-100">
              <Row>
                <Col md={6} lg={6}>
                  <div className="detail-text-card margin-top">
                    <FontAwesomeIcon
                      className="detail-icon"
                      icon={faDoorClosed}
                    />
                    <h4>Cemetery Cleaning Services</h4>
                    <p>Preserving peace through meticulous cemetery care</p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="detail-text-card">
                    <FontAwesomeIcon
                      className="detail-icon"
                      icon={faUtensils}
                    />
                    <h4>Compassionate Food Services</h4>
                    <p>
                      Professional staff to manage setup, service, and cleanup
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="detail-text-card margin-lefttop">
                    <FontAwesomeIcon className="detail-icon" icon={faMap} />
                    <h4>Personalized Flyers and Banners</h4>
                    <p>Create memorable tributes with customizable designs</p>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="detail-text-card margin-plustop">
                    {/* <FontAwesomeIcon className="detail-icon" icon={faTree} /> */}
                    <h4>Funeral Arrangements</h4>
                    <p>
                      We handle Pooja and funeral arrangements with care and
                      respect, honoring your traditions and wishes.
                    </p>
                  </div>
                </Col>

                <Col md={12} lg={12}>
                  <div className="detail-text-card margin-lefttop  mt-5">
                    <FontAwesomeIcon className="detail-icon" icon={faTree} />
                    <h4>Find your ancestors</h4>
                    <p>
                      Bring to life your family’s history by exploring the lives
                      of those that came before you with the help of Nanyaar
                      map, Ancestors search and Nanyaar matches
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      
      <section className="video_section">
        <Container>
          <Row>
            <h3>NaanYaar Documentary</h3>
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation
              pagination={{
                clickable: true,
              }}
              modules={[Navigation, Autoplay, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/E-YHpGDeUqQ?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/bDof62ci0fg?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/9XLudiJmOJI?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/wY99FJ2DsbY?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/qLNL1c16s90?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="5" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                   <iframe src="https://www.youtube.com/embed/9QEEHjEBtKM?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="6" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>


              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/1Qva4lxlXWQ?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="7" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/36bRHT9RhVM?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/awRKF7pP12s?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="9" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

            </Swiper>
          </Row>
        </Container>
      </section>

      <section className="footer-sec">
        <Footer />
      </section>
    </>
  );
}
export default Home;
