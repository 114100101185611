import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { userProfileUpdateValidator } from "../validators/Validators";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaUser } from "react-icons/fa";
import dayjs from "dayjs";
import "../assets/css/bootstrap.min.css";
import "../assets/css/menu.css";
import "../assets/css/style.css";
import "../assets/css/vendors.css";
import {
  clearMessage,
  profileUpdateGetRequest,
  profileUpdatePostRequest,
} from "../store/user/user.action";
import { userSelector } from "../store/user/user.model";
import {
  getlocationDataRequest,
  settingSelector,
} from "../store/settings/settings.action";
import Swal from "sweetalert2";

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* Selector */
  const { getLocData } = useSelector(settingSelector);
  const { getProfile, updateProfile } = useSelector(userSelector);
  console.log(updateProfile, "updateProfile");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(userProfileUpdateValidator),
  });
  console.log(errors, "fsdlkfdf");
  const onSubmitHandler = (formData) => {
    console.log(formData, "vgbhnj");
    dispatch(profileUpdatePostRequest(formData));
  };
  /* UseEffect */
  useEffect(() => {
    dispatch(profileUpdateGetRequest());
    dispatch(getlocationDataRequest());
  }, []);

  useEffect(() => {
    if (getProfile) {
      reset({
        name: getProfile?.name,
        email: getProfile?.email,
        gender: getProfile.gender,
        phone_no: getProfile.phone_no,
        locations: getProfile.locations,
      });
      const formattedDate = dayjs(getProfile.dob).format("YYYY-MM-DD");
      setValue("dob", formattedDate);
    }
  }, [setValue, getProfile]);

  useEffect(() => {
    if (updateProfile) {
      Swal.fire({
        icon: "success",
        title: "Updated Succesfully",
      });
      dispatch(clearMessage());
    }
  }, [updateProfile]);

  return (
    <>
      <div>
        <section>
          <Container>
            <Row className="profile m-5">
              <Col md={4} className="mt-5.5">
                <div className="card-body-style image">
                  {/* <Col className="profile-col" md={4}> */}
                  <div className="profile-bg">
                    <FaUser size={50} className="circle" />
                  </div>
                  <div className="d-flex justify-content-center w-100">
                    <p>{getProfile?.email}</p>
                  </div>
                  {/* </Col> */}
                </div>
              </Col>
              <Col md={8}>
                <div>
                  <h3>Profile Update</h3>
                  <Form
                    onSubmit={handleSubmit(onSubmitHandler)}
                    className="profile-form"
                  >
                    <Row>
                      <Col md={6}>
                        <div>
                          <Form.Label>Name:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            {...register("name")}
                          />
                          {errors?.name && (
                            <div>
                              <p className="text-danger">
                                {errors?.name?.message}
                              </p>
                            </div>
                          )}
                        </div>
                      </Col>
                      {/* <Col md={6}>
                        <div>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Email"
                            {...register("email")}
                            disabled
                          />
                        </div>
                      </Col> */}{" "}
                      <Col md={6}>
                        <div>
                          <Form.Label>Phone NO:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Phone No"
                            {...register("phone_no")}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div>
                          <Form.Label>Location</Form.Label>
                          <Form.Control
                            as="select"
                            className="w-100 "
                            variant="outlined"
                            {...register("locations")}
                          >
                            <option>SELECT</option>
                            {getLocData?.map((ele) => {
                              return <option value={ele.id}>{ele.name}</option>;
                            })}
                          </Form.Control>
                          {errors?.locations && (
                            <div>
                              <p className="text-danger">
                                {errors?.locations?.message}
                              </p>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div>
                          <Form.Label>Gender</Form.Label>
                          <Form.Control
                            as="select"
                            className="w-100 "
                            variant="outlined"
                            label={"Gender"}
                            {...register("gender")}
                          >
                            <option>SELECT</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </Form.Control>
                          {errors?.gender && (
                            <div>
                              <p className="text-danger">
                                {errors?.gender?.message}
                              </p>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div>
                          <Form.Label>DOB</Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="DOB"
                            {...register("dob")}
                          />
                          {errors?.dob && (
                            <div>
                              <p className="text-danger">
                                {errors?.dob?.message}
                              </p>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="text-end">
                      <Button className="update" type="submit">
                        Update
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Settings;
