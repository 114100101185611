export const UserActionTypes = {
  LOGIN_USER_REQUEST: "@@user/LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS: "@@user/LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "@@user/LOGIN_USER_FAILURE",

  SIGNUP_USER_REQUEST: "@@user/SIGNUP_USER_REQUEST",
  SIGNUP_USER_SUCCESS: "@@user/SIGNUP_USER_SUCCESS",
  SIGNUP_USER_FAILURE: "@@user/SIGNUP_USER_FAILURE",

  USER_LOGOUT: "@@user/USER_LOGOUT",
  PROFILEUPDATEPOST_REQUEST: '@@user/PROFILEUPDATEPOST_REQUEST',
  PROFILEUPDATEPOST_SUCCESS: '@@user/PROFILEUPDATEPOST_SUCCESS',
  PROFILEUPDATEPOST_FAILURE: '@@user/PROFILEUPDATEPOST_FAILURE',

  PROFILEUPDATEGET_REQUEST: '@@user/PROFILEUPDATEGET_REQUEST',
  PROFILEUPDATEGET_SUCCESS: '@@user/PROFILEUPDATEGET_SUCCESS',
  PROFILEUPDATEGET_FAILURE: '@@user/PROFILEUPDATEGET_FAILURE',

  CLEARMESSAGES: '@@user/CLEARMESSAGES',
};

export const userInitialState = {
  user: {},
  stats: {},
  isLoading: false,
  coinBalance: [],
  signinUser: {},
  signinisLoading: false,
  profilepostLoad: false,
  profilegetLoad: false,
  getProfile: [],
  signUpLoad: false,
  signupStatus: false,
  updateProfile: false,
};

export const userSelector = (state) => state.UserReducer;
