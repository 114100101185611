import { call, takeEvery, put } from "redux-saga/effects";
import {
  addMemberFailure,
  addMemberSuccess,
  getPersDataFailure,
  getPersDataSuccess,
  getTreeFailure,
  getTreeSuccess,
  postPersDataFailure,
  postPersDataSuccess,
  relationFailure,
  relationSuccess,
  relativesFailure,
  relativesSuccess,
  userDataRecFailure,
  userDataRecSuccess,
} from "./familyTree.action";
import { TreeActionItems } from "./familyTree.model";
import {
  createTree,
  getpersDataList,
  getTree,
  persDataList,
  relationList,
  relativeList,
  userDataList,
} from "./familyTree.api";
function* handleGetGeoData(action) {
  try {
    const response = yield call(getTree, action.payload);
    yield put(getTreeSuccess(response));
  } catch (error) {
    yield put(getTreeFailure(error));
  }
}

function* handleAddMember(action) {
  try {
    const response = yield call(createTree, action.payload);
    yield put(addMemberSuccess(response));
  } catch (error) {
    yield put(addMemberFailure(error));
  }
}

function* handleRelations(action) {
  try {
    const response = yield call(relationList);
    yield put(relationSuccess(response));
  } catch (error) {
    yield put(relationFailure(error));
  }
}

function* handleRelatives(action) {
  try {
    const respone = yield call(relativeList);
    yield put(relativesSuccess(respone));
  } catch (error) {
    yield put(relativesFailure(error));
  }
}

function* handleGetUserData(action) {
  try {
    const response = yield call(userDataList);
    yield put(userDataRecSuccess(response));
  } catch (e) {
    yield put(userDataRecFailure(e));
  }
}

function* handlePersonData(action) {
  try {
    const response = yield call(persDataList, action.payload);
    yield put(postPersDataSuccess(response));
  } catch (e) {
    yield put(postPersDataFailure(e));
  }
}

function* handeCreateGet(action) {
  try {
    const response = yield call(getpersDataList);
    yield put(getPersDataSuccess(response));
  } catch (e) {
    yield put(getPersDataFailure(e));
  }
}

export function* FamilyTreeSaga() {
  yield takeEvery(TreeActionItems.GETTREENODE_REQUEST, handleGetGeoData);
  yield takeEvery(TreeActionItems.ADDMEMBER_REQUEST, handleAddMember);
  yield takeEvery(TreeActionItems.RELATIONS_REQUEST, handleRelations);
  yield takeEvery(TreeActionItems.RELATIVES_REQUEST, handleRelatives);
  yield takeEvery(TreeActionItems.USERDATAGET_REQUEST, handleGetUserData);
  yield takeEvery(TreeActionItems.PERSONALDATAPOST_REQUEST, handlePersonData);
  yield takeEvery(TreeActionItems.PERSONALDATAGET_REQUEST, handeCreateGet);
}
