import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { UserActionTypes } from "./user/user.model";
import { AuthReducer } from "./auth/auth.reducer";
import { TestReducer } from "./test/test.reducer";
import { menu } from "./menu/menu.reducer";
import { SettingReducer } from "./settings/settings.reducer";
import { TreeReducer } from "./familyTree/familyTree.reducer";
import { UserReducer } from "./user/user.reducer";
import { adminReducer,  } from "./admin/admin.reducer";

const appReducer = combineReducers({
  AuthReducer,
  TestReducer,
  menu,
  SettingReducer,
  TreeReducer,
  UserReducer,
  adminReducer,
});

const persistConfig = {
  key: "nanyar",
  storage: storageSession,
};
const rootReducer = (state, action) => {
  if (action.type === UserActionTypes.USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
