import React, { useRef, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  IoHome,
  IoIdCard,
  IoPerson,
  IoEaselSharp,
  IoSettings,
} from "react-icons/io5";
import {
  MdManageAccounts,
  MdOutlineMonitor,
  MdOutlineWork,
} from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";
import { MENU_BEHAVIOUR, MENU_PLACEMENT } from "../../utils/Constants";
import {
  useWindowSize,
  checkBehaviour,
  checkPlacement,
  isDeeplyDiffBehaviourStatus,
  isDeeplyDiffPlacementStatus,
} from "../../utils/config";
import CsLineIcons from "../../components/cs-line-icons/CsLineIcons";
import { useNavigate, Link } from "react-router-dom";
// import {
//   userProfile,
//   signInSelector,
//   clearData,
// } from "../../../store/reducer/SignInReducer";
import profile from "../../assets/img/avatar.webp";
import {
  menuChangeCollapseAll,
  menuChangeAttrMenuAnimate,
  menuChangePlacementStatus,
  menuChangeBehaviourStatus,
  menuChangeAttrMobile,
  menuChangeNavClasses,
} from "../../store/menu/menu.action";
import { logout } from "../../store/user/user.action";
import { RiServiceLine } from "react-icons/ri";
import { PiTreeStructureFill } from "react-icons/pi";
import { LiaGiftsSolid } from "react-icons/lia";
import Logo from "../../assets/img/unnamed.png";
import { RxDashboard } from "react-icons/rx";
import { GrGallery } from "react-icons/gr";
// import { StudentSelector } from "../../../store/reducer/StudentReducer";
const DELAY = 80;

const Nav = () => {
  const name = localStorage.getItem("username");
  const role = localStorage.getItem("role");
  const [menuList, setMenuList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    navClasses,
    placement,
    behaviour,
    placementStatus,
    behaviourStatus,
    attrMobile,
    breakpoints,
    useSidebar,
  } = useSelector((state) => state.menu);
  console.log(navClasses, placement, behaviour, "45r6yh");

  // const { signInData, signInLoading, profileData, errorMessage } =
  //   useSelector(signInSelector);
  // const { studentProfileDatas } = useSelector(StudentSelector);
  // console.log(studentProfileDatas?.studentData, "SGFSDHFDJTKU");

  // let studentStatus = studentProfileDatas?.studentData?.hiring_partner_student?.filter(
  //   (ele) => {
  //     return ele.student_acceptance_status == 3;
  //   }
  // );
  // let isShortlisted = studentStatus?.length != 0 ? 3 : 0;
  // studentProfileDatas?.studentData?.hiring_partner_student &&
  // studentProfileDatas?.studentData?.hiring_partner_student[0]
  //   ?.student_acceptance_status;

  const mouseActionTimer = useRef(null);
  const { width } = useWindowSize();
  const hideMobileMenu = () => {
    let newNavClasses = {
      ...navClasses,
      "mobile-side-out": true,
      "mobile-side-ready": true,
      "mobile-side-in": false,
    };
    dispatch(menuChangeNavClasses(newNavClasses));
    setTimeout(() => {
      newNavClasses = {
        ...newNavClasses,
        "mobile-side-ready": false,
        "mobile-side-out": false,
        "mobile-top-ready": true,
      };
      dispatch(menuChangeNavClasses(newNavClasses));
    }, 200);
    setTimeout(() => {
      newNavClasses = {
        ...newNavClasses,
        "mobile-top-in": true,
        "mobile-top-ready": true,
      };
      dispatch(menuChangeNavClasses(newNavClasses));
      dispatch(menuChangeAttrMobile(false));
    }, 230);
  };

  const getMenuStatus = useCallback(
    (pBreakpoints, pPlacement, pBehaviour) => {
      if (pBreakpoints) {
        const placementStatusCB = checkPlacement({
          placement: pPlacement,
          breakpoints: pBreakpoints,
        });
        const behaviourStatusCB = checkBehaviour({
          placement: placementStatusCB.placementHtmlData,
          behaviour: pBehaviour,
          breakpoints: pBreakpoints,
        });

        if (isDeeplyDiffPlacementStatus(placementStatusCB, placementStatus)) {
          dispatch(menuChangePlacementStatus(placementStatusCB));
        }
        if (isDeeplyDiffBehaviourStatus(behaviourStatusCB, behaviourStatus)) {
          dispatch(menuChangeBehaviourStatus(behaviourStatusCB));
        }
      }
      // eslint-disable-next-line
    },
    [behaviourStatus, placementStatus, breakpoints]
  );

  useEffect(() => {
    if (width && placement && behaviour && breakpoints) {
      getMenuStatus(breakpoints, placement, behaviour);
    }
    // eslint-disable-next-line
  }, [width, breakpoints, placement, behaviour]);

  useEffect(() => {
    if (navClasses && navClasses["mobile-side-in"]) {
      window.addEventListener("click", hideMobileMenu);
    }
    return () => {
      window.removeEventListener("click", hideMobileMenu);
    };
    // eslint-disable-next-line
  }, [navClasses]);
  // Starts mobile menu opening sequence
  const showMobileMenu = (e) => {
    e.preventDefault();
    dispatch(menuChangeAttrMobile(true));
    let newNavClasses = {
      ...navClasses,
      "mobile-top-out": true,
      "mobile-top-in": false,
      "mobile-top-ready": false,
    };
    dispatch(menuChangeNavClasses(newNavClasses));
    setTimeout(() => {
      newNavClasses = {
        ...newNavClasses,
        "mobile-top-out": false,
        "mobile-side-ready": true,
      };
      dispatch(menuChangeNavClasses(newNavClasses));
    }, 200);
    setTimeout(() => {
      newNavClasses = {
        ...newNavClasses,
        "mobile-side-in": true,
      };
      dispatch(menuChangeNavClasses(newNavClasses));
    }, 230);
  };
  // Vertical menu semihidden state showing
  // Only works when the vertical menu is active and mobile menu closed
  const onMouseEnterDelay = () => {
    if (
      placementStatus.placementHtmlData === MENU_PLACEMENT.Vertical &&
      behaviourStatus.behaviourHtmlData === MENU_BEHAVIOUR.Unpinned &&
      attrMobile !== true
    ) {
      dispatch(menuChangeCollapseAll(false));
      dispatch(menuChangeAttrMenuAnimate("show"));
    }
  };

  // Delayed one that hides or shows the menu. It's required to prevent collapse animation getting stucked
  const onMouseEnter = () => {
    if (mouseActionTimer.current) clearTimeout(mouseActionTimer.current);

    mouseActionTimer.current = setTimeout(() => {
      onMouseEnterDelay();
    }, DELAY);
  };

  // Vertical menu semihidden state hiding
  // Only works when the vertical menu is active and mobile menu closed
  const onMouseLeaveDelay = () => {
    if (
      placementStatus.placementHtmlData === MENU_PLACEMENT.Vertical &&
      behaviourStatus.behaviourHtmlData === MENU_BEHAVIOUR.Unpinned &&
      attrMobile !== true
    ) {
      dispatch(menuChangeCollapseAll(true));
      dispatch(menuChangeAttrMenuAnimate("hidden"));
    }
  };

  const onMouseLeave = () => {
    if (mouseActionTimer.current) clearTimeout(mouseActionTimer.current);
    mouseActionTimer.current = setTimeout(() => {
      onMouseLeaveDelay();
    }, DELAY);
  };

  // useEffect(() => {
  //   let formData = {};
  //   dispatch(userProfile({ formData }));
  // }, []);

  useEffect(() => {
    if (role == 1) {
      setMenuList([
        { label: "TreeView", to: "/tree", icon: <MdOutlineMonitor /> },
        { label: "Gallery", to: "/gallery", icon: <GrGallery /> },
        { label: "Services", to: "/services", icon: <RiServiceLine /> },
        { label: "Settings", to: "/settings", icon: <IoSettings /> },
      ]);
    } else if (role == 0) {
      setMenuList([
        { label: "DashBoard ", to: "/dashboard", icon: <RxDashboard /> },
        {
          label: "Genology Page",
          to: "/genology",
          icon: <PiTreeStructureFill />,
        },
        { label: "Services", to: "/services", icon: <LiaGiftsSolid /> },
        { label: "Manage Event", to: "/events", icon: <MdManageAccounts /> },
        { label: "Admin User's ", to: "/users", icon: <IoSettings /> },
      ]);
    }
  }, [role]);

  return (
    <div
      id="nav"
      className={classNames("nav-container d-flex", navClasses)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="nav-content d-flex">
        {/* <div className="logo position-relative">
          <Link>
            <div className="img"></div>
          </Link>
        </div> */}
        <img src={Logo} className="w-100 px-3" />
        {console.log(role, "ROLES")}
        <div className="language-switch-container">
          <button
            className="btn btn-empty language-button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={() => {
              localStorage.clear();
              dispatch(logout());
              navigate("/home");
            }}
          >
            <CsLineIcons icon="logout" className="me-2" size="17" />{" "}
            <span className="align-middle">Logout</span>
          </button>
        </div>
        <div class="user-container d-flex"></div>
        <div className="menu-container flex-grow-1 os-host os-theme-dark os-host-overflow os-host-overflow-x os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
          <div className="os-resize-observer-host observed">
            <div className="os-resize-observer"></div>
          </div>
          <div className="os-size-auto-observer observed">
            <div className="os-resize-observer"></div>
          </div>
          <div className="os-content-glue"></div>
          <div className="os-padding w-100">
            <div className="os-viewport os-viewport-native-scrollbars-invisible">
              <div className="os-content">
                <ul id="menu" className="menu show">
                  {menuList.map((menus, index) => {
                    return (
                      <>
                        <li>
                          <Link
                            to={menus?.to}
                            className="d-flex justify-content-start align-items-center"
                          >
                            {menus?.icon}
                            <span className="label">{menus?.label}</span>
                          </Link>
                        </li>
                      </>
                    );
                  })}
                  {/* <li>
                    <Link
                      to={"/genology"}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <IoHome />
                      <span className="label">GenologyPage</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/dashboard"}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <MdOutlineMonitor />
                      <span className="label">Dashboard</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/tree"}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <MdOutlineMonitor />
                      <span className="label">TreeView</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/settings"}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <IoSettings />
                      <span className="label">Settings</span>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
            <div className="os-scrollbar-track os-scrollbar-track-off">
              <div className="os-scrollbar-handle"></div>
            </div>
          </div> */}
          {/* <div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden">
            <div className="os-scrollbar-track os-scrollbar-track-off">
              <div className="os-scrollbar-handle"></div>
            </div>
          </div> */}
          {/* <div className="os-scrollbar-corner"></div> */}
        </div>
        {/* <div className="mobile-buttons-container">
          <a
            href="#"
            id="scrollSpyButton"
            className="spy-button"
            data-bs-toggle="dropdown"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="acorn-icons acorn-icons-menu-dropdown undefined"
            >
              <path d="M2 3 18 3M2 10 18 10M13 15 10.3536 17.6464C10.1583 17.8417 9.84171 17.8417 9.64645 17.6464L7 15"></path>
            </svg>
          </a>
          <div
            className="dropdown-menu dropdown-menu-end"
            id="scrollSpyDropdown"
          ></div>
          <a
            href="#"
            id="mobileMenuButton"
            className="menu-button"
            onClick={showMobileMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="acorn-icons acorn-icons-menu undefined"
            >
              <path d="M2 3 18 3M2 10 18 10M2 17 18 17"></path>
            </svg>
          </a>
        </div> */}
      </div>
      {/* <div className="nav-shadow"></div> */}
    </div>
  );
};
export default Nav;
