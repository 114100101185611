import { action } from "typesafe-actions";
import { GroupActionTypes } from "./settings.model";

export const addGroupRequest = (req) =>
  action(GroupActionTypes.ADD_GROUP_REQUEST, req);

export const addGroupSuccess = (res) =>
  action(GroupActionTypes.ADD_GROUP_SUCCESS, res);

export const addGroupFailure = (err) =>
  action(GroupActionTypes.ADD_GROUP_FAILURE, err);

export const clearGroupMessages = (err) =>
  action(GroupActionTypes.CLEAR_MESSAGES, err);

export const getusergroupRequest = (err) =>
  action(GroupActionTypes.GET_USERGROUP_REQUEST, err);

export const getusergroupSuccess = (err) =>
  action(GroupActionTypes.GET_USERGROUP_SUCESS, err);

export const getusergroupFailure = (err) =>
  action(GroupActionTypes.GET_USERGROUP_FAILURE, err);

export const getsocialSuccess = (err) =>
  action(GroupActionTypes.GET_SOCIAL_SUCESS, err);

export const getsocialFailure = (err) =>
  action(GroupActionTypes.GET_SOCIAL_FAILURE, err);

export const getsocialRequest = (err) =>
  action(GroupActionTypes.GET_SOCIAL_REQUEST, err);

export const usergroupUpdateSucess = (props) =>
  action(GroupActionTypes.UPDATE_USERGROUP_SUCESS, props);

export const usergroupUpdateFailure = (props) =>
  action(GroupActionTypes.UPDATE_USERGROUP_FAILURE, props);

export const usergroupUpdateRequest = (props) =>
  action(GroupActionTypes.UPDATE_USERGROUP_REQUEST, props);

export const getfbpagedetailsRequest = (props) =>
  action(GroupActionTypes.GET_FB_PAGE_REQUEST, props);

export const getfbpagedetailsFailed = (props) =>
  action(GroupActionTypes.GET_FB_PAGE_FAILURE, props);

export const getfbpagedetailsSuccess = (props) =>
  action(GroupActionTypes.GET_FB_PAGE_SUCESS, props);

export const getcurrentGrpRequest = (props) =>
  action(GroupActionTypes.GET_CURRENT_GRP_REQUEST, props);

export const getcurrentGrpFailed = (props) =>
  action(GroupActionTypes.GET_CURRENT_GRP_FAILURE, props);

export const getcurrentGrpSuccess = (props) =>
  action(GroupActionTypes.GET_CURRENT_GRP_SUCESS, props);

export const authSocialsRequest = (props) =>
  action(GroupActionTypes.AUTH_SOCIALS_REQUEST, props);

export const authSocialsFailed = (props) =>
  action(GroupActionTypes.AUTH_SOCIALS_FAILURE, props);

export const authSocialsSuccess = (props) =>
  action(GroupActionTypes.AUTH_SOCIALS_SUCESS, props);

export const setCrntgrpRequest = (props) =>
  action(GroupActionTypes.SET_CRNT_GRP_REQUEST, props);

export const setCrntgrpFailed = (props) =>
  action(GroupActionTypes.SET_CRNT_GRP_FAILURE, props);

export const setCrntgrpSuccess = (props) =>
  action(GroupActionTypes.SET_CRNT_GRP_SUCESS, props);

export const getProfileRequest = (props) =>
  action(GroupActionTypes.GET_PROFILE_SETTING_REQUEST, props);

export const getProfileSuccess = (props) =>
  action(GroupActionTypes.GET_PROFILE_SETTING_SUCCESS, props);

export const getProfileFailure = (props) =>
  action(GroupActionTypes.GET_PROFILE_SETTING_FAILURE, props);

export const postProfileRequest = (props) =>
  action(GroupActionTypes.POST_PROFILE_SETTING_REQUEST, props);

export const postProfileSuccess = (props) =>
  action(GroupActionTypes.POST_PROFILE_SETTING_SUCCESS, props);

export const postProfileFailure = (props) =>
  action(GroupActionTypes.POST_PROFILE_SETTING_FAILURE, props);

export const settingsChangeColor = (props) =>
  action(GroupActionTypes.CHANGE_COLOR, props);

export const setColor = (props) => action(GroupActionTypes.SET_COLOR, props);
export const setThemeValue = (props) =>
  action(GroupActionTypes.SET_THEME_VALUES, props);

/*user Setting  */
export const postAddEventRequest = (req) =>
  action(GroupActionTypes.POST_ADDEVENT_REQUEST, req);
export const postAddEventSuccess = (res) =>
  action(GroupActionTypes.POST_ADDEVENT_SUCCESS, res);
export const postAddEventFailure = (err) =>
  action(GroupActionTypes.POST_ADDEVENT_FAILURE, err);

export const getAddEventRequest = (req) =>
  action(GroupActionTypes.GET_ADDEVENT_REQUEST, req);
export const getAddEventSuccess = (res) =>
  action(GroupActionTypes.GET_ADDEVENT_SUCCESS, res);
export const getAddEventFailure = (err) =>
  action(GroupActionTypes.GET_ADDEVENT_FAILURE, err);

export const getserviceDataRequest = (req) =>
  action(GroupActionTypes.GET_SERVICE_REQUEST, req);
export const getserviceDataSuccess = (res) =>
  action(GroupActionTypes.GET_SERVICE_SUCCESS, res);
export const getserviceDataFailure = (err) =>
  action(GroupActionTypes.GET_SERVICE_FAILURE, err);

export const getlocationDataRequest = (req) =>
  action(GroupActionTypes.GET_LOCATION_REQUEST, req);
export const getlocationDataSuccess = (res) =>
  action(GroupActionTypes.GET_LOCATION_SUCCESS, res);
export const getlocationDataFailure = (err) =>
  action(GroupActionTypes.GET_LOCATION_FAILURE, err);

export const postUpdateEventRequest = (req) =>
  action(GroupActionTypes.POST_UPDATEEVENT_REQUEST, req);
export const postUpdateEventSuccess = (res) =>
  action(GroupActionTypes.POST_UPDATEEVENT_SUCCESS, res);
export const postUpdateEventFailure = (err) =>
  action(GroupActionTypes.POST_UPDATEEVENT_FAILURE, err);

export const addphotoRequest = (req) =>
  action(GroupActionTypes.ADD_PHOTOS_REQUEST, req);

export const addphotoSuccess = (res) =>
  action(GroupActionTypes.ADD_PHOTOS_SUCCESS, res);

export const addphotoFailure = (err) =>
  action(GroupActionTypes.ADD_PHOTOS_FAILED, err);

export const getphotoRequest = (req) =>
  action(GroupActionTypes.GET_PHOTOS_REQUEST, req);

export const getphotoSuccess = (res) =>
  action(GroupActionTypes.GET_PHOTOS_SUCCESS, res);

export const getphotoFailure = (err) =>
  action(GroupActionTypes.GET_PHOTOS_FAILED, err);

export const settingSelector = (state) => state.SettingReducer;
