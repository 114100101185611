import { all } from "redux-saga/effects";
// import { AuthSaga } from "./auth/auth.saga";
import { TestSaga } from "./test/test.saga";
import { AuthSaga } from "./auth/auth.saga";
import { FamilyTreeSaga } from "./familyTree/familyTree.saga";
import { UserSaga } from "./user/user.saga";
import { adminSaga } from "./admin/admin.saga";
import { SettingSaga } from "./settings/settings.saga";

export default function* rootSaga() {
  yield all([
    TestSaga(),
    AuthSaga(),
    FamilyTreeSaga(),
    UserSaga(),
    SettingSaga(),
    adminSaga()
  ]);
}
