import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { signInValidator } from "../validators/Validators";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

/** Internal Imports */
import { clearMessage, loginUserRequest } from "../store/user/user.action";
import { userSelector } from "../store/user/user.model";
import "../assets/css/bootstrap.min.css";
import "../assets/css/menu.css";
import "../assets/css/style.css";
import "../assets/css/vendors.css";
import { message } from "../utils/message";

const Login = () => {
  /** React Component Functions */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = localStorage.getItem("isAuthenticated");
  const role = localStorage.getItem("role");

  /** Selectors */
  const { signinUser, userMessage } = useSelector(userSelector);
  console.log(signinUser, "45tyhb");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(signInValidator),
  });

  const hanldeSignin = (formData) => {
    console.log(formData, "4789785478");
    dispatch(loginUserRequest(formData));
  };

  useEffect(() => {
    if (userMessage == message.loginSuccess) {
      Swal.fire({
        title: message.loginSuccess,
        icon: "success",
      });
      dispatch(clearMessage());
    }
    if (isAuth) {
      if (signinUser && signinUser.login_status === 1) {
        if (role == 1) navigate("/tree");
        else if (role == 0) navigate("/dashboard");
      } else {
        navigate("/personaldetail");
      }
    }
  }, [signinUser, isAuth, role, userMessage]);

  return (
    <>
      <div class="container-fluid">
        <div class="row row-height">
          <div class="col-xl-4 col-lg-4 content-left">
            <div class="content-left-wrapper">
              {/* <!-- <a href="index.html" id="logo"><img src="img/logo.png" alt="" width="45" height="45"></a> --> */}
              <div id="social">
                <ul>
                  <li>
                    <a href="#0">
                      <i class="bi bi-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-twitter-x"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-tiktok"></i>
                    </a>
                  </li>
                </ul>
              </div>
              {/* <!-- /social --> */}

              <div class="login_section">
                <h2 class="section_title">Signin</h2>
                <form
                  className="tooltip-end-bottom"
                  onSubmit={handleSubmit(hanldeSignin)}
                >
                  <div class="form-group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      class="form-control required"
                      placeholder="email"
                      {...register("email")}
                    />

                    {errors?.email?.message && (
                      <div>
                        <p className="error text-danger">
                          {errors?.email?.message}
                        </p>
                      </div>
                    )}
                  </div>

                  <div class="form-group">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      class="form-control required"
                      placeholder="Password"
                      {...register("password")}
                    />
                    {errors?.password?.message && (
                      <div>
                        <p className="error text-danger">
                          {errors?.password?.message}
                        </p>
                      </div>
                    )}
                  </div>
                  <div class="submit_btn">
                    <button class="btn_1 rounded yellow" type="submit">
                      Sign In
                    </button>
                  </div>
                </form>
                <div class="sign_text">
                  <p>
                    Not a member?
                    <div onClick={() => navigate("/signup")}>
                      <u>Sign up for free</u>
                    </div>
                  </p>
                </div>
              </div>
              <div class="copy">© 2024 Nanyar</div>
            </div>
            {/* <!-- /content-left-wrapper --> */}
          </div>
          {/* <!-- /content-left --> */}
          <div class="col-xl-8 col-lg-8 content-right bg_ad" id="start">
            <div class="background"></div>
          </div>
          {/* <!-- /content-right--> */}
        </div>
        {/* <!-- /row--> */}
      </div>
      {/* <!-- /container-fluid --> */}
    </>
  );
};

export default Login;
