import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const signIn = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/user/sign_in", postData);
};

export const signUp = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/user/sign_up", postData);
};

export const postProfileDataList = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(baseUrl +"/user/updateprofile" ,payload, Http.getAuthToken());
};

export const getProfileDataList = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user/getprofile" , Http.getAuthToken());
};