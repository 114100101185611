import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const addgroup = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/user_group/create",
    payload,
    Http.getAuthToken()
  );
};
export const getProfile = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user/getuserdetails", Http.getAuthToken());
};

export const updateProfile = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(baseUrl, "Baseurl");
  return Http.put(
    baseUrl + "/user/update_user_details",
    payload,
    Http.getAuthToken()
  );
};
export const getusergroup = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user_group/get_usergroup", Http.getAuthToken());
};

export const getSocial = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/social/get_social", Http.getAuthToken());
};

export const updategroup = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/user_group/update",
    payload,
    Http.getAuthToken()
  );
};

export const getfbpagedetails = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user_group/get_fb_page", Http.getAuthToken());
};

export const getSelectedGroup = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user_group/selected_group", Http.getAuthToken());
};

export const authorizeSocial = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/user_group/authorize_social",
    payload,
    Http.getAuthToken()
  );
};

export const updateCurrent = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(payload, "kadiufhikadiufhi");
  return Http.put(
    baseUrl + "/user_group/update_current/" + payload,
    { id: payload },
    Http.getAuthToken()
  );
};
export const addEventList = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/services/create-event",
    payload,
    Http.getAuthToken()
  );
};

export const getEventList = ({ id, page, limit }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/services/events?query=" +
      id +
      "&page=" +
      page +
      "&limit=" +
      limit,
    Http.getAuthToken()
  );
};

export const getServiceList = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/services/", Http.getAuthToken());
};

export const getLocationList = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/settings/locations", Http.getAuthToken());
};

export const updateEventList = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/services/update-event-status",
    payload,
    Http.getAuthToken()
  );
};

export const addGalleryPhoto = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/settings/add-photos",
    payload,
    Http.getFileAuthToken()
  );
};

export const getGalleryPhoto = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/settings/gallery", Http.getAuthToken());
};
