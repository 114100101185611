import { settingActionItems, settingInitialState } from "./admin.model";

const reducer = (state = settingInitialState, action) => {
  switch (action.type) {
    case settingActionItems.RELATIVESDATA_REQUEST:
      return {
        ...state,
        relativesLoad: true,
      };
    case settingActionItems.RELATIVESDATA_SUCCESS:
      return {
        ...state,
        relativesLoad: false,
        relationdata: action.payload?.data?.datas?.data,
      };
    case settingActionItems.RELATIVESDATA_FAILURE:
      return {
        ...state,
        relativesLoad: false,
      };

    case settingActionItems.CREATE_SERVICE_REQUEST:
      return {
        ...state,
        serviceLoad: true,
        postSucces: false,
      };
    case settingActionItems.CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        serviceLoad: false,
        postSucces: true,
      };
    case settingActionItems.CREATE_SERVICE_FAILURE:
      return {
        ...state,
        serviceLoad: false,
        postSucces: false,
      };
    case settingActionItems.ADMINUSER_REQUEST:
      return {
        ...state,
        aduserLoad: true,
      };
    case settingActionItems.ADMINUSER_SUCCESS:
      return {
        ...state,
        aduserLoad: false,
        userData: action.payload?.data?.data,
      };
    case settingActionItems.ADMINUSER_FAILURE:
      return {
        ...state,
        aduserLoad: false,
      };
    case settingActionItems.GETALLSTATUS_REQUEST:
      return {
        ...state,
        staudLoad: true,
      };
    case settingActionItems.GETALLSTATUS_SUCCESS:
      return {
        ...state,
        staudLoad: false,
        statusData: action.payload?.data?.data,
      };
    case settingActionItems.GETALLSTATUS_FAILURE:
      return {
        ...state,
        staudLoad: false,
      };
    case settingActionItems.GETDASHBOARD_REQUEST:
      return {
        ...state,
        dashLoad: true,
      };
    case settingActionItems.GETDASHBOARD_SUCCESS:
      return {
        ...state,
        dashLoad: false,
        getDashData: action.payload?.data?.data,
      };
    case settingActionItems.GETDASHBOARD_FAILURE:
      return {
        ...state,
        dashLoad: false,
      };
    case settingActionItems.DELETE_SERVICE_REQUEST:
      return {
        ...state,
        delSerLoad: true,
        deleteSuccess: false,
      };
    case settingActionItems.DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        delSerLoad: false,
        deleteSuccess: true,
      };
    case settingActionItems.DELETE_SERVICE_FAILURE:
      return {
        ...state,
        delSerLoad: false,
        deleteSuccess: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export { reducer as adminReducer };
