import { DEFAULT_SETTINGS } from "../../utils/config";
import { message } from "../../utils/message";
import {
  GroupActionTypes,
  getComputedValues,
  groupInitialState,
} from "./settings.model";

const reducer = (state = groupInitialState, action) => {
  switch (action.type) {
    case GroupActionTypes.ADD_GROUP_SUCCESS:
      var { payload } = action;
      console.log(payload, "add success payload");
      return {
        ...state,
        groupLoading: false,
        groupMessage: payload?.data?.message,
      };
    case GroupActionTypes.ADD_GROUP_REQUEST:
      return {
        ...state,
        groupLoading: true,
      };
    case GroupActionTypes.ADD_GROUP_FAILURE:
      var { payload } = action;
      return {
        ...state,
        groupLoading: false,
        groupError: payload.response.data.message,
      };
    case GroupActionTypes.CLEAR_MESSAGES:
      var { payload } = action;
      return {
        ...state,
        groupError: "",
        groupMessage: "",
      };
    case GroupActionTypes.GET_USERGROUP_SUCESS:
      var { payload } = action;
      return {
        ...state,
        group: payload?.data?.data,
      };
    case GroupActionTypes.GET_USERGROUP_REQUEST:
      return {
        ...state,
        // groupLoading: true,
      };
    case GroupActionTypes.GET_USERGROUP_FAILURE:
      return {
        ...state,
        // groupLoading: true,
      };
    case GroupActionTypes.GET_SOCIAL_SUCESS:
      var { payload } = action;
      console.log(payload, "payload GTE");
      return {
        ...state,
        socialList: payload?.data,
      };
    case GroupActionTypes.UPDATE_USERGROUP_REQUEST:
      var { payload } = action;
      return {
        ...state,
        accountSubmit: true,
      };
    case GroupActionTypes.UPDATE_USERGROUP_FAILURE:
      var { payload } = action;
      return {
        ...state,
        accountSubmit: false,
      };
    case GroupActionTypes.UPDATE_USERGROUP_SUCESS:
      var { payload } = action;
      return {
        ...state,
        accountSubmit: false,
        groupMessage: "Socials Updated Successfully",
      };
    case GroupActionTypes.GET_CURRENT_GRP_SUCESS:
      var { payload } = action;
      return {
        ...state,
        current_group: payload?.data?.data,
      };
    case GroupActionTypes.SET_CRNT_GRP_SUCESS:
      var { payload } = action;
      return {
        ...state,
        groupMessage: "Current Group Updated",
      };
    case GroupActionTypes.AUTH_SOCIALS_SUCESS:
      var { payload } = action;
      console.log(payload);
      return {
        ...state,
        groupMessage: payload?.data?.message,
      };
    case GroupActionTypes.GET_FB_PAGE_SUCESS:
      var { payload } = action;
      return {
        ...state,
        groupMessage: payload?.data?.message,
      };
    case GroupActionTypes.GET_PROFILE_SETTING_REQUEST:
      return {
        ...state,
        profileLoading: true,
      };
    case GroupActionTypes.GET_PROFILE_SETTING_SUCCESS:
      var { payload } = action;
      console.log(payload, "fromGetProfileconsole");
      return {
        ...state,
        profileLoading: false,
        profileDetails: payload,
      };
    case GroupActionTypes.GET_PROFILE_SETTING_FAILURE:
      var { payload } = action;
      return {
        ...state,
        profileLoading: false,
        groupMessage: payload?.data.message,
      };
    case GroupActionTypes.POST_PROFILE_SETTING_REQUEST:
      return {
        ...state,
        profileLoading: true,
      };
    case GroupActionTypes.POST_PROFILE_SETTING_SUCCESS:
      var { payload } = action;
      console.log(payload, "fjgdkddfjd");
      return {
        ...state,
        profileLoading: false,
        groupMessage: payload?.data?.message,
        profileDetail: payload,
      };
    case GroupActionTypes.POST_PROFILE_SETTING_FAILURE:
      var { payload } = action;
      return {
        ...state,
        profileLoading: false,
        groupMessage: payload?.data.message,
      };
    case GroupActionTypes.SET_COLOR:
      console.log(action, "color payload");
      return {
        ...state,
        color: action.payload,
      };
    case GroupActionTypes.SET_THEME_VALUES:
      return {
        ...state,
        themeValues: getComputedValues(),
      };
    case GroupActionTypes.SETTINGS_CHANGE_RADIUS:
      return {
        ...state,
        radius: action.payload,
      };
    case GroupActionTypes.SETTINGS_CHANGE_NAVCOLOR:
      return {
        ...state,
        navColor: action.payload,
      };
    case GroupActionTypes.SETTINGS_CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.payload,
      };
    case GroupActionTypes.SETTINGS_RESET_LAYOUT:
      return {
        ...state,
        layout: DEFAULT_SETTINGS.LAYOUT,
      };

    /* user Add Event */
    case GroupActionTypes.POST_ADDEVENT_REQUEST:
      return {
        ...state,
        postEventLoad: true,
        postSuccess: false,
      };
    case GroupActionTypes.POST_ADDEVENT_SUCCESS:
      return {
        ...state,
        postEventLoad: false,
        postSuccess: true,
      };
    case GroupActionTypes.POST_ADDEVENT_FAILURE:
      return {
        ...state,
        postEventLoad: false,
        postSuccess: false,
      };

    case GroupActionTypes.GET_ADDEVENT_REQUEST:
      return {
        ...state,
        getEventLoad: true,
      };
    case GroupActionTypes.GET_ADDEVENT_SUCCESS:
      return {
        ...state,
        getEventLoad: false,
        getEventData: action.payload?.data?.data,
      };
    case GroupActionTypes.GET_ADDEVENT_FAILURE:
      return {
        ...state,
        getEventLoad: false,
      };

    case GroupActionTypes.GET_SERVICE_REQUEST:
      return {
        ...state,
        getserLoad: true,
      };
    case GroupActionTypes.GET_SERVICE_SUCCESS:
      return {
        ...state,
        getserLoad: false,
        getServiceDate: action.payload?.data?.data,
      };
    case GroupActionTypes.GET_SERVICE_FAILURE:
      return {
        ...state,
        getserLoad: false,
      };

    case GroupActionTypes.GET_LOCATION_REQUEST:
      return {
        ...state,
        getLocLoad: true,
      };
    case GroupActionTypes.GET_LOCATION_SUCCESS:
      return {
        ...state,
        getLocLoad: false,
        getLocData: action.payload?.data?.data,
      };
    case GroupActionTypes.GET_LOCATION_FAILURE:
      return {
        ...state,
        getLocLoad: false,
      };

    case GroupActionTypes.POST_UPDATEEVENT_REQUEST:
      return {
        ...state,
        updateEveLoad: true,
        updateSucces: false,
      };
    case GroupActionTypes.POST_UPDATEEVENT_SUCCESS:
      return {
        ...state,
        updateEveLoad: false,
        updateSucces: true,
      };
    case GroupActionTypes.POST_UPDATEEVENT_FAILURE:
      return {
        ...state,
        updateEveLoad: false,
        updateSucces: false,
      };
    case GroupActionTypes.ADD_PHOTOS_REQUEST:
      return {
        ...state,
        notifyMessage: "",
      };
    case GroupActionTypes.ADD_PHOTOS_SUCCESS:
      return {
        ...state,
        notifyMessage: message.addphotoSucess,
      };
    case GroupActionTypes.ADD_PHOTOS_FAILED:
      return {
        ...state,
        notifyMessage: "",
      };
    case GroupActionTypes.GET_PHOTOS_REQUEST:
      return {
        ...state,
      };
    case GroupActionTypes.GET_PHOTOS_SUCCESS:
      console.log(action, 7635263);
      return {
        ...state,
        galleries: {
          photos: action.payload?.data?.data,
          total: action.payload?.data?.count,
        },
      };
    case GroupActionTypes.GET_PHOTOS_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export { reducer as SettingReducer };
