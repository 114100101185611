import { action } from "typesafe-actions";
import { settingActionItems } from "./admin.model";

export const relativesGetRequest = (req) =>
  action(settingActionItems.RELATIVESDATA_REQUEST, req);
export const realativeGetSuccess = (res) =>
  action(settingActionItems.RELATIVESDATA_SUCCESS, res);
export const relativeGetFailure = (err) =>
  action(settingActionItems.RELATIVESDATA_FAILURE, err);

export const createServiceReq = (req) =>
  action(settingActionItems.CREATE_SERVICE_REQUEST, req);
export const createServiceSuccess = (res) =>
  action(settingActionItems.CREATE_SERVICE_SUCCESS, res);
export const createServiceFailure = (err) =>
  action(settingActionItems.CREATE_SERVICE_FAILURE, err);

export const getAdminUserRequest = (req) =>
  action(settingActionItems.ADMINUSER_REQUEST, req);
export const getAdminUserSuccess = (res) =>
  action(settingActionItems.ADMINUSER_SUCCESS, res);
export const getAdminUserFailure = (err) =>
  action(settingActionItems.ADMINUSER_FAILURE, err);


export const getUserStatusRequest = (req) =>
  action(settingActionItems.GETALLSTATUS_REQUEST, req);
export const getUserStatusSuccess = (res) =>
  action(settingActionItems.GETALLSTATUS_SUCCESS, res);
export const getUserStatusFailure = (err) =>
  action(settingActionItems.GETALLSTATUS_FAILURE, err);

export const getUserDashboardRequest = (req) =>
  action(settingActionItems.GETDASHBOARD_REQUEST, req);
export const getUserDashboardSuccess = (res) =>
  action(settingActionItems.GETDASHBOARD_SUCCESS, res);
export const getUserDashboardFailure = (err) =>
  action(settingActionItems.GETDASHBOARD_FAILURE, err);

export const deleteServiceRequest = (req) =>
  action(settingActionItems.DELETE_SERVICE_REQUEST, req);
export const deleteServiceSuccess = (res) =>
  action(settingActionItems.DELETE_SERVICE_SUCCESS, res);
export const deleteServiceFailure = (err) =>
  action(settingActionItems.DELETE_SERVICE_FAILURE, err);