const namespace = "@@family_tree";

export const settingActionItems = {
  RELATIVESDATA_REQUEST: `${namespace}/RELATIVESDATA_REQUEST`,
  RELATIVESDATA_SUCCESS: `${namespace}/RELATIVESDATA_SUCCESS`,
  RELATIVESDATA_FAILURE: `${namespace}/RELATIVESDATA_FAILURE`,

  CREATE_SERVICE_REQUEST: `${namespace}/CREATE_SERVICE_REQUEST`,
  CREATE_SERVICE_SUCCESS: `${namespace}/CREATE_SERVICE_SUCCESS`,
  CREATE_SERVICE_FAILURE: `${namespace}/CREATE_SERVICE_FAILURE`,

  ADMINUSER_REQUEST: `${namespace}/ADMINUSER_REQUEST`,
  ADMINUSER_SUCCESS: `${namespace}/ADMINUSER_SUCCESS`,
  ADMINUSER_FAILURE: `${namespace}/ADMINUSER_FAILURE`,

  GETALLSTATUS_REQUEST: `${namespace}/GETALLSTATUS_REQUEST`,
  GETALLSTATUS_SUCCESS: `${namespace}/GETALLSTATUS_SUCCESS`,
  GETALLSTATUS_FAILURE: `${namespace}/GETALLSTATUS_FAILURE`,

  GETDASHBOARD_REQUEST: `${namespace}/GETDASHBOARD_REQUEST`,
  GETDASHBOARD_SUCCESS: `${namespace}/GETDASHBOARD_SUCCESS`,
  GETDASHBOARD_FAILURE: `${namespace}/GETDASHBOARD_FAILURE`,

  DELETE_SERVICE_REQUEST: `${namespace}/DELETE_SERVICE_REQUEST`,
  DELETE_SERVICE_SUCCESS: `${namespace}/DELETE_SERVICE_SUCCESS`,
  DELETE_SERVICE_FAILURE: `${namespace}/DELETE_SERVICE_FAILURE`,
};
export const settingInitialState = {
  relativesLoad: false,
  relationdata: [],
  serviceLoad: false,
  serviceData: [],
  postSucces:false,
  aduserLoad:false,
  userData:[],
  staudLoad:false,
  statusData:[],
  dashLoad:false,
  getDashData:[],
  delSerLoad:false,
  deleteSuccess:false,
};
export const adminSelector = (state) => state.adminReducer;
