import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Form, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  getserviceDataRequest,
  settingSelector,
} from "../store/settings/settings.action";
import { useDispatch, useSelector } from "react-redux";
import { addServiceValidator } from "../validators/Validators";
import {
  createServiceReq,
  deleteServiceRequest,
} from "../store/admin/admin.action";
import { adminSelector } from "../store/admin/admin.model";
import { CircularProgress } from "@mui/material";

const Services = () => {
  const dispatch = useDispatch();

  /* Selectors */
  const { getServiceDate } = useSelector(settingSelector);
  const { postSucces, deleteSuccess } = useSelector(adminSelector);
  console.log(getServiceDate, postSucces, "dfdfdfdfs");
  /* useForm */
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(addServiceValidator),
  });

  /* States */
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => setOpenModal(true);
  const closeModalHandler = () => setOpenModal(false);
  const [isLoading, setIsLoading] = useState(true);

  /* Functions */
  const submitData = (formData) => {
    console.log(formData, "sdsds32");
    dispatch(createServiceReq(formData));
    if (postSucces === true) {
      dispatch(getserviceDataRequest());
    }
  };

  const handleRejectClick = (id) => {
    console.log(id, "4e5rtfyghb");
    const payload = {
      id: id,
    };
    dispatch(deleteServiceRequest(payload));
    if (deleteSuccess) {
      dispatch(getserviceDataRequest());
 


    }
  };

  /* useEffect */
  useEffect(() => {
    dispatch(getserviceDataRequest());
    const delay = 500;
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, delay);
    if (postSucces) {
      dispatch(getserviceDataRequest());
      setOpenModal(false);
      reset();
    }
    return () => clearTimeout(timeoutId);
  }, [postSucces]);
  return (
    <div>
      <div className="text-start mx-4 mt-5">
        <h3>Service Add</h3>
      </div>
      <div className="text-end mx-5">
        <Button onClick={openModalHandler}>Add Event</Button>
      </div>
      <Card className="mt-3 mx-3">
        <CardBody>
          <div className="m-2 table-responsive">
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>SERVICE NAME</th>
                  <th>DESCTIPTION</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {!isLoading && (
                  <>
                    {getServiceDate.length!==0 ?(getServiceDate?.map((ele, index) => {
                      return (
                        <tr key={ele.id}>
                          <td>{ele.name}</td>
                          <td>
                            {ele.description ? ele.description : "- - - - - "}
                          </td>
                          <td>
                            {" "}
                            <Button
                              variant="danger"
                              onClick={() => handleRejectClick(ele.id)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })):(
                      <tr style={{ height: "100px" }}>
                  <td colSpan="3" className="text-center pt-5">
                    No Data Found
                  </td>
                </tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      {isLoading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "300px" }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
      <Modal show={openModal} onHide={closeModalHandler} size="MD">
        <Form onSubmit={handleSubmit(submitData)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Enter Event Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Service Name"
                {...register("name")}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter description"
                {...register("description")}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModalHandler}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Services;
