import { message } from "../../utils/message";
import { TreeActionItems, treeInitialState } from "./familyTree.model";

const reducer = (state = treeInitialState, action) => {
  switch (action.type) {
    case TreeActionItems.GETTREENODE_REQUEST:
      return {
        ...state,
        treeLoading: true,
      };
    case TreeActionItems.GETTREENODE_SUCESS:
      var { payload } = action;
      return {
        ...state,
        treeLoading: false,
        treeData: payload?.data?.data,
        treeMessage: message.treeNodeSuccess
      };
    case TreeActionItems.GETTREENODE_FAILURE:
      return {
        ...state,
        treeLoading: false,
      };
    case TreeActionItems.ADDMEMBER_REQUEST:
      return {
        ...state,
        addMemberLoading: true,
        treeMessage: "",
      };
    case TreeActionItems.ADDMEMBER_SUCCESS:
      return {
        ...state,
        addMemberLoading: false,
        treeMessage: message.addMembers,
      };
    case TreeActionItems.ADDMEMBER_FAILURE:
      return {
        ...state,
        addMemberLoading: false,
        treeMessage: "",
      };
    case TreeActionItems.RELATIONS_REQUEST:
      return {
        ...state,
        relationLoading: true,
      };
    case TreeActionItems.RELATIONS_SUCCESS:
      var { payload } = action;
      return {
        ...state,
        relationLoading: false,
        relationData: payload?.data?.data,
      };
    case TreeActionItems.RELATIONS_FAILURE:
      return {
        ...state,
        relationLoading: false,
      };
    case TreeActionItems.RELATIVES_REQUEST:
      return {
        ...state,
        relativesLoad: true,
      };
    case TreeActionItems.RELATIVES_SUCCESS:
      var { payload } = action;
      console.log(payload, "relativesData");
      return {
        ...state,
        relativesLoad: false,
        relativesData: payload?.data?.datas?.data,
      };
    case TreeActionItems.RELATIVES_FAILURE:
      return {
        ...state,
        relativesLoad: false,
      };

    case TreeActionItems.USERDATAGET_REQUEST:
      return {
        ...state,
        userDataLoad: true,
      };
    case TreeActionItems.USERDATAGET_SUCCESS:
      var { payload } = action;
      return {
        ...state,
        userDataLoad: false,
        userInfo: payload?.data?.data,
      };
    case TreeActionItems.USERDATAGET_FAILURE:
      return {
        ...state,
        userDataLoad: false,
      };
    case TreeActionItems.PERSONALDATAPOST_REQUEST:
      return {
        ...state,
        perDatapostLoading: true,
      };
    case TreeActionItems.PERSONALDATAPOST_SUCCESS:
      var { payload } = action;
      return {
        ...state,
        perDatapostLoading: false,
        succesCreate: payload?.data,
      };
    case TreeActionItems.PERSONALDATAPOST_FAILURE:
      return {
        ...state,
        perDatapostLoading: false,
        treeMessage:message.personalData
      };
    case TreeActionItems.PERSONALDATAGET_REQUEST:
      return {
        ...state,
        getCreateLoading: true,
      };
    case TreeActionItems.PERSONALDATAGET_SUCCESS:
      console.log(action.payload, "fsdfj876");
      return {
        ...state,
        getCreateLoading: false,
        getCreateData: action.payload?.data,
      };
    case TreeActionItems.PERSONALDATAGET_FAILURE:
      return {
        ...state,
        getCreateLoading: false,
      };
    case TreeActionItems.MEMBER_CLEAR:
      alert("");
      return {
        ...state,
        treeData: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export { reducer as TreeReducer };
