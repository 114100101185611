import { Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faChartSimple, faCreditCard, faLock, faMagnifyingGlass, faPhone, faTree, faUser } from "@fortawesome/free-solid-svg-icons";
import marketing from "../../src/assets/img/marketing.jpg";
import { Swiper, SwiperSlide, } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function Familytreesurvey(){

    return(
        <>
        <Header/>

            <section className="fam-bg">
                <Container>
                    <div className="bg-txt">
                        <h2>Family Tree Survey</h2>
                    </div>
                </Container>
            </section>

            <section className="work">
                <Container>
                    <div>
                        <h2>How our Genealogical Service Will Work</h2>
                    </div>

                    <div>
                        <Row>
                            <Col md={4} sm={12}>
                                <div className="fam-card">
                                    <div className="titcard">
                                        <FontAwesomeIcon icon={faUser} /><h3>User Registration and Profile Creation</h3>
                                    </div>
                                    <div>
                                        <ul className="box-card">
                                            <li><b>Account Setup:</b> Users create an account using their personal details (name,
                                            email, phone number, etc.). Secure authentication methods like OTP or social
                                            media logins can be offered for added convenience.</li>
                                            <li><b>Profile Management:</b> Once logged in, users can set up a detailed profile
                                            where they can input and manage their family information.</li>
                                            <li><b>User Dashboard:</b> Provide a user-friendly dashboard where users can add
                                            family members and navigate their family tree. This dashboard will be the hub
                                            for all their genealogical activities.</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} sm={12}>
                                <div className="fam-card">
                                    <div className="titcard">
                                        <FontAwesomeIcon icon={faTree} /><h3>Building the Family Tree</h3>
                                    </div>
                                    <div>
                                        <ul className="box-card">
                                            <li><b>Data Input:</b> Users can add information about their parents, grandparents,
                                            great-grandparents, and extended family. Details may include:</li>
                                            <div className="sub">
                                            <li>Name, date, and place of birth.</li>
                                            <li>Education history (schools attended).</li>
                                            <li>Migration details (where they moved, why, and when).</li>
                                            <li>Language spoken, occupation, and significant family events.</li>
                                            <li>Upload options for photos, documents, and stories.</li>
                                            </div>
                                            <li><b>Visualization:</b> Family trees are visualized interactively, allowing users to see
                                            connections and navigate their ancestry easily.</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} sm={12}>
                                <div className="fam-card">
                                    <div className="titcard">
                                        <FontAwesomeIcon icon={faMagnifyingGlass} /><h3>Search and Discovery Features</h3>
                                    </div>
                                    <div>
                                        <ul className="box-card">
                                            <li><b>Search Functionality:</b> Users can search for relatives or ancestors within the
                                            platform. The system matches similar names, dates, and places, helping users
                                            discover potential connections.</li>
                                            <li><b>Related Family Trees:</b> Users can explore other family trees that match or
                                            share connections with their data, fostering community engagement.</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} className="sp">
                                <div className="fam-card">
                                    <div className="titcard">
                                        <FontAwesomeIcon icon={faBriefcase} /><h3>Paid Ancestral Research Services</h3>
                                    </div>
                                    <div>
                                        <ul className="box-card">
                                            <li><b>Service Request:</b> Users can request in-depth ancestral research as a paid
                                            service if they want to trace their roots further. This service will involve:</li>
                                            <div className="sub">
                                                <li><b>Written Consent:</b> Collect written approval from the user for
                                                conducting research, including permissions to gather and use any
                                                sensitive data.</li>
                                                <li><b>Submission of Known Information:</b> Users provide all known
                                                genealogical information, such as names, locations, family stories, and
                                                any existing documents.</li>
                                            </div>
                                            <li><b>On-Ground Research Team:</b> A dedicated team of researchers, historians,
                                            and genealogists will:</li>
                                            <div className="sub">
                                                <li>Visit ancestral places as provided by the user.</li>
                                                <li>Conduct oral interviews with older residents, village elders, or
                                                community members.</li>
                                                <li>Explore local records, temples, or community archives.</li>
                                                <li>Collect video recordings of interviews and gather photographs,
                                                documents, or any other evidence to build a comprehensive ancestral
                                                report.</li>
                                            </div>
                                            <li><b>Report Compilation:</b> After thorough research, a detailed report will be
                                            compiled, including videos, photos, and documents that capture the essence of
                                            the user&#39;s ancestral history.</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>


                        <Row className="spac">
                            <Col md={3} sm={12}>
                                <div className="fam-card">
                                    <div className="titcard">
                                        <FontAwesomeIcon icon={faChartSimple} /><h3>Marketing and Data Collection</h3>
                                    </div>
                                    <div>
                                        <ul className="box-card">
                                            <li><b>Community Outreach:</b> A marketing team will engage with elderly
                                            individuals in communities to gather oral histories and document unrecorded
                                            genealogical data.</li>
                                            <li><b>Video Documentation:</b> Interviews with older individuals will be recorded to
                                            capture authentic stories, providing an irreplaceable link to the past.</li>
                                            <li><b>Verification Process:</b> Collected data will be verified through cross-
                                            referencing with existing reports, local records, and expert consultations to
                                            ensure accuracy.</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} sm={12}>
                                <div className="fam-card">
                                    <div className="titcard">
                                        <FontAwesomeIcon icon={faLock} /><h3>Data Privacy and Security</h3>
                                    </div>
                                    <div>
                                        <ul className="box-card">
                                            <li><b>User Control:</b> Users have full control over their data, including privacy
                                            settings that allow them to decide who can view or access their information.</li>
                                            <li><b>Data Encryption:</b> All personal and genealogical data will be encrypted to
                                            protect against unauthorized access, ensuring user confidentiality.</li>
                                            <li><b>Compliance:</b> The platform will comply with relevant privacy laws, including
                                            GDPR, to safeguard sensitive information.</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} sm={12}>
                                <div className="fam-card">
                                    <div className="titcard">
                                        <FontAwesomeIcon icon={faPhone} /><h3>Customer Support and Guidance</h3>
                                    </div>
                                    <div>
                                        <ul className="box-card">
                                            <li><b>Help Center:</b> Provide a help center with FAQs, tutorials, and guides on using
                                            the platform, building family trees, and requesting research services.</li>
                                            <li><b>Support Team:</b> A dedicated support team available via chat, email, or phone
                                            to assist users with technical issues or genealogical queries.</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} sm={12}>
                                <div className="fam-card">
                                    <div className="titcard">
                                        <FontAwesomeIcon icon={faCreditCard} /><h3>Subscription and Payment Models</h3>
                                    </div>
                                    <div>
                                        <ul className="box-card">
                                            <li><b>Freemium Model:</b> Basic tree-building and search functionalities are free,
                                            encouraging users to get started.</li>
                                            <li><b>Premium Subscriptions:</b> Offer premium features like advanced search
                                            options, access to exclusive records, and data export functionalities.</li>
                                            <li><b>Paid Research Services:</b> Charge fees for in-depth ancestral research based on
                                            the complexity and depth of the investigation required.</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className="eg">
                <Container>
                    <div>
                        <h2>User Journey Example</h2>
                    </div>
                    <Row>
                    <Col md={4} sm={12}>
                        <div className="no"><h1>1</h1></div>
                        <div><p>A user signs up and builds their family tree, adding as much information as they
                        know.</p></div>
                    </Col>
                    <Col md={4} sm={12}>
                        <div className="no"><h1>2</h1></div>
                        <div><p>They explore the platform, find connections, and engage with other users.</p></div>
                    </Col>
                    <Col md={4} sm={12}>
                        <div className="no"><h1>3</h1></div>
                        <div><p>Intrigued by gaps in their family history, they request a paid ancestral research
                        service.</p></div>
                    </Col>
                    <Col md={4} sm={12}>
                        <div className="no"><h1>4</h1></div>
                        <div><p>Your team conducts on-ground research, collects oral histories, and compiles a
                        comprehensive ancestral report, delivered to user.</p></div>
                    </Col>
                    <Col md={4} sm={12}>
                        <div className="no"><h1>5</h1></div>
                        <div><p>The user gains a richer understanding of their heritage, supported by videos, photos,
                        and documents gathered during the research.</p></div>
                    </Col>
                </Row>
          <Row className="video">
          <Swiper
              slidesPerView={3}
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation
              pagination={{
                clickable: true,
              }}
              modules={[Navigation, Autoplay, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/E-YHpGDeUqQ?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/bDof62ci0fg?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/9XLudiJmOJI?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/wY99FJ2DsbY?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/qLNL1c16s90?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="5" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                   <iframe src="https://www.youtube.com/embed/9QEEHjEBtKM?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="6" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>


              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/1Qva4lxlXWQ?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="7" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/36bRHT9RhVM?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

              <SwiperSlide>
                  <div className="video">
                  <iframe src="https://www.youtube.com/embed/awRKF7pP12s?list=PL6pOoldcNqnhxsSCP-mr3RuE25i2nIp6m" title="9" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
              </SwiperSlide>

            </Swiper>
          </Row>
                </Container>
            </section>

            <section className="marketing">
                <Container>
                    <div>
                        <h2>Marketing and Expansion</h2>
                    </div>
                    <Row>
                        <Col md={6}>
                            <div>
                                <h3>Engagement Campaigns:</h3>
                                <p>Use content marketing, social media, and webinars to
                                educate users on the importance of preserving family history.</p>
                                <h3>Community Collaborations:</h3>
                                <p>Partner with local history groups, cultural
                                organizations, and temples to access unique data sources and promote your services.</p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mar">
                                <img src={marketing}/>
                            </div>
                        </Col>
                    </Row>
                    <div className="survey-btn">
                        <a href="https://forms.gle/V43xqPfjB4LR1PxN9">
                            <button>Take a Survey</button>
                        </a>
                    </div>
                </Container>
            </section>


        <Footer/>
        </>
    );
}
export default Familytreesurvey;