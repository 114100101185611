import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AppRoute from "./routes/route";
import {
  authProtectedAdminRoutes,
  authProtectedRoutes,
  publicRoutes,
} from "./routes";
import useLayout from "./layout/Layout";
import "./App.css"
import Services from "./components/Services";

function App() {
  const role = localStorage.getItem("role");
  useLayout();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppRoute />}>
          {role == 1
            ? authProtectedRoutes.map((route, idx) => (
                <Route exact path={route?.path} element={<route.component />} />
              ))
            : authProtectedAdminRoutes.map((route, idx) => (
                <Route exact path={route?.path} element={<route.component />} />
              ))}
        </Route>
        {publicRoutes.map((route, idx) => (
          <Route exact path={route?.path} element={<route.component />} />
        ))}
        <Route
          path="*"
          element={
            <div className="text-center d-flex justify-content-center align-items-center s100vh">
              {" "}
              <b> Not Found !! </b>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
