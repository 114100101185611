import React, { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CiLocationOn } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { relativesGetRequest } from "../store/admin/admin.action";
import { adminSelector } from "../store/admin/admin.model";
import {
  getlocationDataRequest,
  settingSelector,
} from "../store/settings/settings.action";

const GenologyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { relationdata } = useSelector(adminSelector);
  const { getLocData } = useSelector(settingSelector);

  console.log(relationdata, "drtyghbj");
  /* States */
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({
    locations: "",
    // fromDate: "",
    // toDate: "",
    dobFrom: "",
    dobTo: "",
  });
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleFilterSubmit = () => {
    console.log(filters, "Filters:");
    const payload = {
      locations: filters.locations,
      dobfrom: filters.dobFrom,
      dobto: filters.dobTo,
      page: page,
      limit: limit,
    };
    console.log(payload, "sdfsj67uy");
    dispatch(relativesGetRequest(payload));
  };

  useEffect(() => {
    const payload = {
      locations: filters.locations,
      dobfrom: filters.dobFrom,
      dobto: filters.dobTo,
      page: page,
      limit: limit,
    };
    dispatch(relativesGetRequest(payload));
    dispatch(getlocationDataRequest());
  }, [page, limit]);

  return (
    <div>
      <div className="d-flex justify-content-between m-3">
        <h2>Genology Details</h2>
        {/* <Button variant="success" onClick={()=>navigate("/tree")}>+Add</Button> */}
      </div>
      <Card className="">
        <div className="d-flex justify-content-center flex-wrap gap-5 m-4 ">
          <div className="gap-2">
            <CiLocationOn />
            <Form.Label>Location:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Location"
              name="locations"
              value={filters.locations}
              onChange={handleFilterChange}
            />
          </div>
          {/* <div>
            <SlCalender />
            <Form.Label>From Date:</Form.Label>
            <Form.Control
              type="date"
              placeholder="From Date"
              name="fromDate"
              value={filters.fromDate}
              onChange={handleFilterChange}
            />
          </div> */}
          {/* <div>
            <SlCalender />
            <Form.Label>To Date:</Form.Label>
            <Form.Control
              type="date"
              placeholder="To Date"
              name="toDate"
              value={filters.toDate}
              onChange={handleFilterChange}
            />
          </div> */}
          <div>
            {/* <SlCalender className="pb-0.5"/> */}
            <Form.Label>DOB From:</Form.Label>
            <Form.Control
              type="date"
              placeholder="DOB From"
              name="dobFrom"
              value={filters.dobFrom}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            {/* <SlCalender /> */}
            <Form.Label>DOB To:</Form.Label>
            <Form.Control
              type="date"
              placeholder="DOB To"
              name="dobTo"
              value={filters.dobTo}
              onChange={handleFilterChange}
            />
          </div>
          <div className="d-flex align-items-end gap-2">
            <Button variant="secondary" size="md" onClick={handleFilterSubmit}>
              Filter
            </Button>
            <Button variant="success" size="md">
              Export
            </Button>
          </div>
        </div>
      </Card>
      <Card className="mt-4">
        <div className="m-2 table-responsive">
          <Table bordered>
            <thead>
              <tr c>
                <th>Name</th>
                <th>DOB</th>
                <th>Location</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {relationdata.length !== 0 ? (
                relationdata?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{dayjs(item.dob).format("DD/MM/YYYY")}</td>
                    <td>
                      {
                        getLocData?.find((ele) => ele?.id === item.locations)
                          ?.name
                      }
                    </td>
                    <td className="d-flex justify-content-center">
                      {/* <Button variant="primary" size="sm">
                Edit
              </Button> */}
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => navigate(`/tree/${item.id}`)}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr style={{ height: "100px" }}>
                  <td colSpan="5" className="text-center pt-5">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card>
    </div>
  );
};

export default GenologyPage;
