import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";
import Logo from "../assets/img/bg-2.jpg";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ImageUploading from "react-images-uploading";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addphotoRequest,
  getphotoRequest,
  settingSelector,
} from "../store/settings/settings.action";
import { API_BASE } from "../services/config";
import { message } from "../utils/message";

const photos = [
  { src: Logo, width: 800, height: 600, id: 2 },
  { src: Logo, width: 800, height: 600, id: 2 },
  { src: Logo, width: 800, height: 600, id: 2 },
  { src: Logo, width: 800, height: 600, id: 2 },
  { src: Logo, width: 800, height: 600, id: 2 },
  { src: Logo, width: 800, height: 600, id: 2 },
  { src: Logo, width: 800, height: 600, id: 2 },
  { src: Logo, width: 800, height: 600, id: 2 },
  { src: Logo, width: 1600, height: 900 },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Gallery = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openImages, setOpenImages] = useState(false);
  const [photo, setPhoto] = useState();
  const { galleries, notifyMessage } = useSelector(settingSelector);
  const [gal, setGal] = useState([]);
  console.log(photo, "photo");
  const [images, setImages] = useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenImages(false);
  };

  const handleAddPhotos = () => {
    setOpenImages(true);
  };

  const handleUpload = (formData) => {
    console.log(formData, 375965);
    dispatch(addphotoRequest({ images: formData }));
    setOpenImages(false);
  };

  useEffect(() => {
    console.log(galleries, "galleries");
    const data = galleries?.photos?.map((ele) => {
      var sizeInMB = (ele?.image_size / (1024 * 1024)).toFixed(2);
      return {
        src: `${API_BASE}${ele?.image_path}`,
        width: 800,
        height: 600,
        size: `${sizeInMB} MB`,
        id: ele?.id,
      };
    });
    setGal(data);
  }, [galleries]);
  console.log(gal, 36564);

  useEffect(() => {
    if (notifyMessage == message.addphotoSucess) {
      dispatch(getphotoRequest({}));
    }
  }, [notifyMessage]);

  useEffect(() => {
    dispatch(getphotoRequest({}));
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between mx-2 mt-3 align-items-center">
        <h1 className="text-muted">Gallery</h1>
        <Button onClick={handleAddPhotos}>Add Photos</Button>
      </div>
      <RowsPhotoAlbum
        photos={gal}
        onClick={(e) => {
          console.log(e, "image element");
          handleClickOpen();
          setPhoto(e);
        }}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
      >
        {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Modal title
        </DialogTitle> */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div>
          <img src={photo?.photo?.src} alt="" width={"100%"} />
        </div>
        <DialogActions className="d-flex justify-content-between align-items-center">
          <p className="m-0">{photo?.photo?.size}</p>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openImages}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="p-5">
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <>
                <div className="upload__image-wrapper">
                  <div
                    className="border-1 p-4 text-center border-dashed"
                    style={isDragging ? { color: "red" } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    Click or Drop here
                  </div>
                  {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Modal title
          </DialogTitle> */}
                  <div className="text-end">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={onImageRemoveAll}
                    >
                      Remove all images
                    </Button>
                  </div>

                  <div className="p-5">
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image["data_url"]} alt="" width="100%" />
                        <div className="image-item__btn-wrapper text-end">
                          {/* <button onClick={() => onImageUpdate(index)}>
                            Update
                          </button> */}
                          <Button onClick={() => onImageRemove(index)}>
                            Remove
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <DialogActions> */}
                <Button autoFocus onClick={() => handleUpload(imageList)}>
                  Save changes
                </Button>
                {/* </DialogActions> */}
              </>
            )}
          </ImageUploading>
        </div>
      </BootstrapDialog>
    </>
  );
};

export default Gallery;
