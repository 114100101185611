import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const signUp = (payload) => {
  console.log(payload, "jhgsdadgdj sdfsd");
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/sign_up", payload);
};

export const signIn = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/user/sign_in", payload);
}
