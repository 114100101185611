import { call, put, takeEvery } from "redux-saga/effects";
import { adminUserApi, createServicesApi, deleteServicesApi, getDashBoardApi, getStatusApi, relativeListData } from "./admin.api";
import {
  createServiceFailure,
  createServiceSuccess,
  deleteServiceFailure,
  deleteServiceSuccess,
  getAdminUserFailure,
  getAdminUserSuccess,
  getUserDashboardFailure,
  getUserDashboardSuccess,
  getUserStatusFailure,
  getUserStatusSuccess,
  realativeGetSuccess,
  relativeGetFailure,
} from "./admin.action";
import { settingActionItems } from "./admin.model";

function* handleRelatives(action) {
  console.log(action, "fsdfjdsfhk");
  try {
    const response = yield call(relativeListData, action.payload);
    yield put(realativeGetSuccess(response));
  } catch (e) {
    yield put(relativeGetFailure(e));
  }
}

function* handleService(action) {
  try {
    const response = yield call(createServicesApi, action.payload);
    yield put(createServiceSuccess(response));
  } catch (error) {
    yield put(createServiceFailure(error));
  }
}
function* handleGetUser(action) {
  try {
    const response = yield call(adminUserApi, action.payload);
    yield put(getAdminUserSuccess(response));
  } catch (error) {
    yield put(getAdminUserFailure(error));
  }
}

function* handleGetStatus(action) {
  try {
    const response = yield call(getStatusApi, action.payload);
    yield put(getUserStatusSuccess(response));
  } catch (error) {
    yield put(getUserStatusFailure(error));
  }
}

function* handleGetDashboard(action) {
  console.log(action, "ertygbhjk")
  try {
    const response = yield call(getDashBoardApi, action.payload);
    yield put(getUserDashboardSuccess(response));
  } catch (error) {
    yield put(getUserDashboardFailure(error));
  }
}

function* handleDelService(action) {
  try {
    const response = yield call(deleteServicesApi, action.payload);
    yield put(deleteServiceSuccess(response));
  } catch (error) {
    yield put(deleteServiceFailure(error));
  }
}
export function* adminSaga() {
  yield takeEvery(settingActionItems.RELATIVESDATA_REQUEST, handleRelatives);
  yield takeEvery(settingActionItems.CREATE_SERVICE_REQUEST, handleService);
  yield takeEvery(settingActionItems.ADMINUSER_REQUEST, handleGetUser);
  yield takeEvery(settingActionItems.GETALLSTATUS_REQUEST, handleGetStatus);
  yield takeEvery(settingActionItems.GETDASHBOARD_REQUEST, handleGetDashboard);
  yield takeEvery(settingActionItems.DELETE_SERVICE_REQUEST, handleDelService);
}



