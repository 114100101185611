import { action } from "typesafe-actions";
import { UserActionTypes } from "./user.model";

export const loginUserRequest = (req) =>
  action(UserActionTypes.LOGIN_USER_REQUEST, req);
export const loginUserSuccess = (res) =>
  action(UserActionTypes.LOGIN_USER_SUCCESS, res);
export const loginUserFailure = (err) =>
  action(UserActionTypes.LOGIN_USER_FAILURE, err);

export const signUpRequest = (req) =>
  action(UserActionTypes.SIGNUP_USER_REQUEST, req);
export const signUpSuccess = (res) =>
  action(UserActionTypes.SIGNUP_USER_SUCCESS, res);
export const signUpFailure = (err) =>
  action(UserActionTypes.SIGNUP_USER_FAILURE, err);

export const logout = () => action(UserActionTypes.USER_LOGOUT);

export const profileUpdatePostRequest = (req) =>
  action(UserActionTypes.PROFILEUPDATEPOST_REQUEST, req);

export const profileUpdatePostSuccess = (payload) =>
  action(UserActionTypes.PROFILEUPDATEPOST_SUCCESS, payload);

export const profileUpdatePostFailure = (err) =>
  action(UserActionTypes.PROFILEUPDATEPOST_FAILURE, err);

export const profileUpdateGetRequest = (req) =>
  action(UserActionTypes.PROFILEUPDATEGET_REQUEST, req);

export const profileUpdateGetSuccess = (payload) =>
  action(UserActionTypes.PROFILEUPDATEGET_SUCCESS, payload);

export const profileUpdateGetFailure = (err) =>
  action(UserActionTypes.PROFILEUPDATEGET_FAILURE, err);

export const clearMessage = (err) => action(UserActionTypes.CLEARMESSAGES, err);
