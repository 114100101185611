import { all, call, put, takeEvery } from "redux-saga/effects";
import * as UserActions from "./user.action";
import { UserActionTypes } from "./user.model";
import Swal from "sweetalert2";
import { signUp, signIn, postProfileDataList, getProfileDataList } from "./user.api";

export function* handleUpdateUser(action) {
  try {
    const response = yield call(signUp, action.payload);
    yield put(UserActions.signUpSuccess(response));
    Swal.fire({
      title: "Verify your account!",
      text: "Plase check the Mail to verify your account",
      icon: "success",
    });
  } catch (e) {
    Swal.fire({
      title: "Sign Up Denied!",
      text: e?.response?.data?.error,
      icon: "error",
    });
    yield put(UserActions.signUpFailure(e));
  }
}
export function* handleLogin(action) {
  try {
    const response = yield call(signIn, action.payload);
    console.log(response, 4854759);
    yield put(UserActions.loginUserSuccess(response));
  } catch (error) {
    yield put(UserActions.loginUserFailure(error));
    console.log(error, 576756);
    Swal.fire({
      title: "Sign In Denied!",
      text: error?.response?.data?.error,
      icon: "error",
    });
  }
}

function* handlePostUpdate(action) {
  try {
    const response = yield call(postProfileDataList, action.payload);
    yield put(UserActions.profileUpdatePostSuccess(response));
  } catch (e) {
    yield put(UserActions.profileUpdatePostFailure(e));
  }
}

function* handleGetUpdate(action) {
  try {
    const response = yield call(getProfileDataList);
    yield put(UserActions.profileUpdateGetSuccess(response));
  } catch (e) {
    yield put(UserActions.profileUpdateGetFailure(e));
  }
}
export function* UserSaga() {
  yield takeEvery(UserActionTypes.SIGNUP_USER_REQUEST, handleUpdateUser);
  yield takeEvery(UserActionTypes.LOGIN_USER_REQUEST, handleLogin);
  yield takeEvery(UserActionTypes.PROFILEUPDATEPOST_REQUEST, handlePostUpdate);
  yield takeEvery(UserActionTypes.PROFILEUPDATEGET_REQUEST, handleGetUpdate);
}
