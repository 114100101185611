import { call, delay, put, takeEvery } from "redux-saga/effects";
import { decryptdata, encryptdata } from "../../utils/encrypt&decrypt";
import Swal from "sweetalert2";
import { secretKey } from "../../services/config";
import { GroupActionTypes } from "./settings.model";
import {
  addgroup,
  authorizeSocial,
  getProfile,
  updateProfile,
  getSelectedGroup,
  getSocial,
  getfbpagedetails,
  getusergroup,
  updateCurrent,
  updategroup,
  addEventList,
  getEventList,
  getServiceList,
  getLocationList,
  updateEventList,
  addGalleryPhoto,
  getGalleryPhoto,
} from "./settings.api";
import {
  addGroupFailure,
  addGroupSuccess,
  getProfileSuccess,
  getProfileFailure,
  authSocialsFailed,
  authSocialsSuccess,
  getcurrentGrpFailed,
  getcurrentGrpSuccess,
  getfbpagedetailsFailed,
  getfbpagedetailsSuccess,
  getsocialFailure,
  getsocialSuccess,
  getusergroupFailure,
  getusergroupSuccess,
  setCrntgrpSuccess,
  usergroupUpdateFailure,
  usergroupUpdateSucess,
  postProfileSuccess,
  postProfileFailure,
  setColor,
  setThemeValue,
  postAddEventSuccess,
  postAddEventFailure,
  getAddEventSuccess,
  getAddEventFailure,
  getserviceDataSuccess,
  getserviceDataFailure,
  getlocationDataSuccess,
  getlocationDataFailure,
  postUpdateEventSuccess,
  postUpdateEventFailure,
  addphotoSuccess,
  addphotoFailure,
  addphotoRequest,
  getphotoSuccess,
  getphotoFailure,
} from "./settings.action";

export function* createGroup(action) {
  try {
    const response = yield call(addgroup, action.payload);
    yield put(addGroupSuccess(response));
  } catch (error) {
    yield put(addGroupFailure(error));
  }
}

export function* getProfileDetails(action) {
  try {
    const response = yield call(getProfile, action.payload);
    console.log(response, "ProfileDataaa");
    yield put(getProfileSuccess(response));
  } catch (error) {
    yield put(getProfileFailure(error));
  }
}

export function* PostProfileDetails(action) {
  try {
    const response = yield call(updateProfile, action.payload);
    yield put(postProfileSuccess(response));
  } catch (error) {
    yield put(postProfileFailure(error));
  }
}

export function* getUserGroup(action) {
  try {
    const response = yield call(getusergroup, action.payload);
    yield put(getusergroupSuccess(response));
  } catch (error) {
    yield put(getusergroupFailure(error));
  }
}

export function* getSocials(action) {
  try {
    const response = yield call(getSocial, action.payload);
    yield put(getsocialSuccess(response));
  } catch (error) {
    yield put(getsocialFailure(error));
  }
}

export function* updateUserGroup(action) {
  try {
    const response = yield call(updategroup, action.payload);
    yield put(usergroupUpdateSucess(response));
  } catch (error) {
    yield put(usergroupUpdateFailure(error));
  }
}

export function* GetFBPages(action) {
  try {
    const response = yield call(getfbpagedetails, action.payload);
    yield put(getfbpagedetailsSuccess(response));
  } catch (error) {
    yield put(getfbpagedetailsFailed(error));
  }
}

export function* handleSettingsChangeColor({ payload }) {
  yield put(setColor(payload));
  yield call(delay, 10);
  yield put(setThemeValue());
}

export function* GetCurrentGroup(action) {
  try {
    const response = yield call(getSelectedGroup, action.payload);
    console.log(response, "curent respo");
    yield put(getcurrentGrpSuccess(response));
  } catch (error) {
    yield put(getcurrentGrpFailed(error));
  }
}

export function* AuthSocials(action) {
  try {
    const response = yield call(authorizeSocial, action.payload);
    yield put(authSocialsSuccess(response));
  } catch (error) {
    yield put(authSocialsFailed(error));
  }
}

export function* UpdateCurrentGrp(action) {
  try {
    console.log(action, "kadiufhi");
    const response = yield call(updateCurrent, action.payload);
    console.log("first groupMessage", response);
    yield put(setCrntgrpSuccess(response));
    // yield put(GroupActionTypes.SET_CRNT_GRP_SUCESS, response);
  } catch (error) {
    // yield put(GroupActionTypes.SET_CRNT_GRP_FAILURE,error);
  }
}
function* handlePostEvent(action) {
  try {
    const response = yield call(addEventList, action.payload);
    yield put(postAddEventSuccess(response));
    Swal.fire({
      title: "Event Updated SuccessFully",
      text: "Your Event Updated, Our Team Will Contact You within 1 Or 2 hours",
      icon: "success",
    });
  } catch (e) {
    yield put(postAddEventFailure(e));
    Swal.fire({
      title: "Event Updated Failed",
      text: e.error.message,
      icon: "success",
    });
  }
}

function* handleGetEvent(action) {
  try {
    const response = yield call(getEventList, action.payload);
    yield put(getAddEventSuccess(response));
  } catch (e) {
    yield put(getAddEventFailure);
  }
}
function* handleGetService(action) {
  try {
    const response = yield call(getServiceList);
    yield put(getserviceDataSuccess(response));
  } catch (e) {
    yield put(getserviceDataFailure(e));
  }
}
function* handleGetLocation(action) {
  try {
    const response = yield call(getLocationList);
    yield put(getlocationDataSuccess(response));
  } catch (e) {
    yield put(getlocationDataFailure(e));
  }
}
function* handleupdateEvent(action) {
  try {
    const response = yield call(updateEventList, action.payload);
    yield put(postUpdateEventSuccess(response));
    Swal.fire({
      title: "Event Updated SuccessFully",
      text: "Your Event Updated",
      icon: "success",
    });
  } catch (e) {
    yield put(postUpdateEventFailure(e));
    Swal.fire({
      title: "Event Updated Failed",
      text: e.error.message,
      icon: "success",
    });
  }
}
// export const settingsChangeColor = (color) => async (dispatch) => {
//   dispatch(setColor(color));
//   await wait(10);
//   dispatch(setThemeValues());
// };

function* handleGalleryPost({ payload }) {
  try {
    console.log(payload, 563465);
    const resposne = yield call(addGalleryPhoto, payload);
    yield put(addphotoSuccess(resposne));
  } catch (error) {
    yield put(addphotoFailure(error));
  }
}

function* handleGalleryGet({ payload }) {
  try {
    const resposne = yield call(getGalleryPhoto, {});
    yield put(getphotoSuccess(resposne));
  } catch (error) {
    yield put(getphotoFailure(error));
  }
}

export function* SettingSaga() {
  yield takeEvery(GroupActionTypes.ADD_GROUP_REQUEST, createGroup);
  yield takeEvery(
    GroupActionTypes.GET_PROFILE_SETTING_REQUEST,
    getProfileDetails
  );
  yield takeEvery(
    GroupActionTypes.POST_PROFILE_SETTING_REQUEST,
    PostProfileDetails
  );
  yield takeEvery(GroupActionTypes.GET_USERGROUP_REQUEST, getUserGroup);
  yield takeEvery(GroupActionTypes.GET_SOCIAL_REQUEST, getSocials);
  yield takeEvery(GroupActionTypes.UPDATE_USERGROUP_REQUEST, updateUserGroup);
  yield takeEvery(GroupActionTypes.GET_FB_PAGE_REQUEST, GetFBPages);
  yield takeEvery(GroupActionTypes.GET_CURRENT_GRP_REQUEST, GetCurrentGroup);
  yield takeEvery(GroupActionTypes.AUTH_SOCIALS_REQUEST, AuthSocials);
  yield takeEvery(GroupActionTypes.SET_CRNT_GRP_REQUEST, UpdateCurrentGrp);
  yield takeEvery(GroupActionTypes.CHANGE_COLOR, handleSettingsChangeColor);
  yield takeEvery(GroupActionTypes.POST_ADDEVENT_REQUEST, handlePostEvent);
  yield takeEvery(GroupActionTypes.GET_ADDEVENT_REQUEST, handleGetEvent);
  yield takeEvery(GroupActionTypes.GET_SERVICE_REQUEST, handleGetService);
  yield takeEvery(GroupActionTypes.GET_LOCATION_REQUEST, handleGetLocation);
  yield takeEvery(GroupActionTypes.POST_UPDATEEVENT_REQUEST, handleupdateEvent);
  yield takeEvery(GroupActionTypes.ADD_PHOTOS_REQUEST, handleGalleryPost);
  yield takeEvery(GroupActionTypes.GET_PHOTOS_REQUEST, handleGalleryGet);
}
