import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddEventRequest,
  postUpdateEventRequest,
  settingSelector,
} from "../store/settings/settings.action";
import { Button, Card, CardBody, Table } from "react-bootstrap";
import dayjs from "dayjs";
import { RxCross2 } from "react-icons/rx";
import { MdDone } from "react-icons/md";

const AdminEvent = () => {
  const dispatch = useDispatch();
  const { getEventData, updateSucces } = useSelector(settingSelector);
  console.log(getEventData, "suifshdkfj");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState(0);

  const handleSuccessClick = (id, ind) => {
    console.log(id, ind, "56t7yghbjj");
    const payload = {
      query: id,
      status: 1,
    };
    dispatch(postUpdateEventRequest(payload));
    if (updateSucces == true) {
      setStatus(1);
    }
  };

  const handleRejectClick = (id, ind) => {
    const payload = {
      query: id,
      status: 3,
    };
    dispatch(postUpdateEventRequest(payload));
    if (updateSucces == true) {
      setStatus(3);
    }
  };

  const handleCompleted = (id) => {
    const payload = {
      query: id,
      status: 2,
    };
    dispatch(postUpdateEventRequest(payload));
    if (updateSucces == true) {
      setStatus(5);
    }
  };
  const renderButtons = (status, id, index) => {
    console.log(status, id, index, "45tygvhbn");
    switch (status) {
      case 0:
        return (
          <>
            <Button onClick={() => handleSuccessClick(id, index)}>
              <MdDone />
            </Button>
            <Button
              variant="danger"
              onClick={() => handleRejectClick(id, index)}
            >
              <RxCross2 />
            </Button>
          </>
        );
      case 1:
        return (
          <>
            <Button onClick={() => handleCompleted(id, index)}>
              <MdDone /> Completed
            </Button>
          </>
        );
      case 2:
        return <p>Completed</p>;
      case 3:
        return <p>Rejected</p>;
      case 4:
        return <p className="text-danger">User Cancelled</p>;
      default:
        return null;
    }
  };
  useEffect(() => {
    const payload = {
      id: "",
      page: page,
      limit: limit,
    };
    dispatch(getAddEventRequest(payload));
    if (updateSucces == true) {
      const payload = {
        id: "",
        page: page,
        limit: limit,
      };
      dispatch(getAddEventRequest(payload));
    }
  }, [updateSucces]);
  return (
    <div>
      <div className="m-4">
        <h2>Manage Events</h2>
      </div>
      <Card className="mt-3 mx-3">
        <CardBody>
          <div className="m-2 table-responsive">
            <Table bordered>
              <thead>
                <tr c>
                  <th>SERVICE NAME</th>
                  <th>DATE</th>
                  <th>DESCRIPTION</th>
                  <th>PHONE NO</th>
                  <th>LOCATION</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {getEventData.length !== 0 ? (
                  getEventData?.map((ele, ind) => {
                    return (
                      <tr key={ele.id}>
                        <td>{ele.service.name}</td>
                        <td>{dayjs(ele.date).format("DD/MM/YYYY")}</td>
                        <td>{ele.description}</td>
                        <td>{ele.phone_no}</td>
                        <td>{ele.location?.name}</td>
                        <td className="d-flex justify-content-evenly">
                          {renderButtons(ele.status, ele.id, ind)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr style={{ height: "100px" }}>
                    <td colSpan="6" className="text-center pt-5">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default AdminEvent;
