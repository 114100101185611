import { Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";
import what from "../../src/assets/img/what.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faHandshake, faTree } from "@fortawesome/free-solid-svg-icons";

function About(){
    return(
        <>
        <Header/>

        <section className="page-bg">
            <Container>
                <div className="bg-txt">
                    <h2>About Us</h2>
                </div>
            </Container>
        </section>

        <section className="what-bg">
            <Container>
                <Row>
                    <Col md={6}>
                        <div className="what">
                            <span>What We Do</span>
                            <h2>Discover Your Family's Story</h2>
                            <p>At Nanyaar, we help you uncover the rich tapestry of your ancestry. Explore your lineage through comprehensive research, build and manage your family tree, and connect with relatives worldwide. Join us to celebrate and preserve your unique family heritage.</p>
                            <button>Reach Us</button>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="what-img">
                            <img src={what}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="mission">
            <Container>
                <Row>
                    <Col md={6}>
                        <div>
                            <span>Our Mission</span>
                            <h2>Empowering Connections Through Ancestry</h2>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div>
                            <p>Our mission is to make genealogy accessible, engaging, and meaningful for everyone. Whether you’re a seasoned family historian or just beginning your journey, Nanyaar provides the tools and resources you need to explore your ancestry, build your family tree, and connect with relatives near and far.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="offer-bg">
            <Container>
                <div className="offer">
                    <span>What We Offer</span>
                    <h2>Uncover Your Ancestral Roots</h2>
                </div>
                <Row>
                    <Col md={4}>
                        <div className="offer-cards">
                            <FontAwesomeIcon icon={faBook} />
                            <h3>Genealogical Research</h3>
                            <p>We offer thorough research services to trace your family's lineage, delving into historical records, archives, and databases. Our experienced genealogists are skilled at uncovering even the most elusive details of your heritage.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="offer-cards">
                            <FontAwesomeIcon icon={faTree} />
                            <h3>Family Tree Building</h3>
                            <p>Our user-friendly family tree builder allows you to easily create, manage, and expand your family tree. Connect with relatives, add historical records, and visualize your family's growth over generations.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="offer-cards">
                            <FontAwesomeIcon icon={faHandshake} />
                            <h3>Community and Collaboration</h3>
                            <p>Share your discoveries, collaborate on research projects, and connect with others who share your passion for family history. Our platform fosters a supportive environment for learning and collaboration.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="mission">
            <Container>
                <Row className="column-reverse">
                    <Col md={6}>
                        <div>
                            <p>At Nanyaar, we are committed to providing a secure and supportive environment for your genealogical journey. We prioritize the privacy of your data and ensure that your information is protected at all times. Our dedicated team of experts is here to support you every step of the way, offering personalized assistance and expert advice.</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="commit">
                            <span>Our Commitment</span>
                            <h2>Dedicated to Your Genealogical Journey</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <Footer/>
        </>
    );
}
export default About;