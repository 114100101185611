import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
// import Header from "../components/layout/Header";
// import Sidebar from "../components/layout/Sidebar";
import { Col, Container, Row } from "react-bootstrap";
import Nav from "../layout/nav/Nav";

const AppRoute = () => {
//   const navigate = useNavigate();
//   console.log("inasdsad");
//   if (document.body) document.body.setAttribute("data-sidebar", "dark");
//   const isAuth = sessionStorage.getItem("isAuthenticated");
//   const isAuthenticated = localStorage.getItem("isAuthenticated");
//   const roleID = localStorage.getItem("role_id");

//   const template = localStorage.getItem("settings");
//   console.log(isAuthenticated, "roleID==");
//   if (!isAuthenticated) {
//     localStorage.clear();
//     return <Navigate to="/signin" replace={true} />;
//   } else {
//     if (!template)
//       localStorage.setItem("template", JSON.stringify({ sidebar: false }));
//     return (
//       <div id="layout-wrapper">
//         <Header />
//         <Sidebar />
//         <Nav />
//         <div className="main-content">
//           <Outlet />
//         </div>
//       </div>
//     );
//   }

const isAuth = localStorage.getItem("isAuthenticated");
const isAuthenticated = 1;

if (!isAuthenticated) {
  localStorage.clear();
}
return isAuth ? (
  <>
    <Nav />
    <main>
      <Container>
        <Row className="h-100">
          <Col className="h-100" id="contentArea">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </main>
  </>
) : (
  <Navigate to="/home" />
);
};
//(props) => (!isAuthenticated ? <Navigate to="/signin" replace={true} /> : <Component {...props} />)

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AppRoute);
