import '../assets/css/homepage.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import naanyarlogo from '../assets/img/naanyarlogo.png';
import whitelogo from '../assets/img/whitelogo.png';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/css/responsive.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
function Header() {
  const navigate=useNavigate()
    const [navbar, setNavbar] = useState(false)

    return (
        <>
            <section className='header-sec'>
                <Container>
                    <Row>
                        <Col md={6} lg={6} sm={5} className='width-50 width-mob'>
                            <div className='header-logo'>
                                <img src={whitelogo} />
                            </div>
                        </Col>
                        <Col md={6} lg={6} sm={7} className='relative width-50 width-mob'>
                            <div className='nav-style'>
                                <ul>
                                    <li><Link to={'/'}>Home</Link></li>
                                    <li><Link to={'/about'}>About</Link></li>
                                    <li><Link to={'/service'}>Services</Link></li>
                                    <li><Link to={'/contact'}>Contact</Link></li>
                                    <li><Button className='orange-btn' onClick={()=>navigate("/signup")}>Signup</Button></li>
                                    <li><Button className='brown-btn' onClick={()=>navigate("/signin")}>Login</Button></li>
                                </ul>
                            </div>
                            <FontAwesomeIcon className='menu-icon' icon={faBars} onClick={() => { setNavbar(!navbar) }} />
                            {navbar === true ?
                                <div className='responsive-nav'>
                                    <ul>
                                        <li><Link to={'/'}>Home</Link></li>
                                        <li><Link to={'/about'}>About</Link></li>
                                        <li><Link to={'/service'}>Services</Link></li>
                                        <li><Link to={'/contact'}>Contact</Link></li>
                                        <li><Button className='orange-btn' onClick={()=> navigate("/signup")}>Signup</Button></li>
                                        <li><Button className='brown-btn' onClick={()=> navigate("/signin")}>Login</Button></li>
                                    </ul>
                                </div>
                                : <></>}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
} export default Header;