import React, { useEffect, useState } from "react";
import { Card, CardBody, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAdminUserRequest } from "../store/admin/admin.action";
import { adminSelector } from "../store/admin/admin.model";
import { CircularProgress } from "@mui/material";

const AdminUsers = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector(adminSelector);
  console.log(userData, 78765778);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(getAdminUserRequest());
    const delay = 500;
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, delay);
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div>
       <div className="text-start mx-4 mt-5">
      <h3>Admin User's</h3>
   </div>
      <Card className="mt-3 mx-3">
        <CardBody>
          <div className="m-2 table-responsive">
            <Table bordered>
              <thead>
                <tr>
                  <th> NAME</th>
                  <th>PHONE NO</th>
                  <th>EMAIL</th>
                  <th>LOCATION</th>
                  <th>ACTION</th>
                </tr>
              </thead>
                  <tbody>
                {!isLoading ? (
                  userData.length !== 0 ? (
                    userData.map((ele) => (
                      <tr key={ele.id}>
                        <td>{ele.name}</td>
                        <td>{ele.phone_no}</td>
                        <td>{ele.email}</td>
                        <td>{ele.locations}</td>
                        <td>{/* Action buttons here */}</td>
                      </tr>
                    ))
                  ) : (
                    <tr style={{height:"100px"}}>
                      <td colSpan="5" className="text-center pt-5">
                        No Data Found
                      </td>
                    </tr>
                  )
                ) : null}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      {isLoading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "300px" }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
    </div>
  );
};

export default AdminUsers;
