import Signin from "../components/Signin";
import Settings from "../components/Settings";
import Signup from "../components/Signup";
import TreeView from "../components/TreeView";
import Activate from "../components/Activate";
import PersonalDetail from "../components/PersonalDetail";
import GenologyPage from "../components/GenologyPage";
import Home from "../components/Home";
import Dashboard from "../components/Dashboard";
import Events from "../components/Events";
import Services from "../components/Services";
import AdminEvent from "../components/AdminEvent";
import AdminUsers from "../components/AdminUsers";
import Gallery from "../components/Gallery";
import About from "../components/About";
import Contact from "../components/Contact";
import ServiceDetail from "../components/ServiceDetail";
import Familytreesurvey from "../components/Familytreesurvey";
window.dispatchEvent(new Event("storage"));
const role = localStorage.getItem("role");
console.log(role, "gfgfgdfgd");
const authProtectedAdminRoutes = [
  { path: "/genology", component: GenologyPage },
  { path: "/services", component: Services },
  { path: "/events", component: AdminEvent },
  { path: "/users", component: AdminUsers },
  { path: "/tree/:query", component: TreeView },
  { path: "/dashboard", component: Dashboard },
];

const authProtectedRoutes = [
  // { path: "/home",  component: Home },
  { path: "/tree", component: TreeView },
  { path: "/settings", component: Settings },
  { path: "/services", component: Events },
  { path: "/gallery", component: Gallery },
  { path: "/personaldetail", component: PersonalDetail },
];

const publicRoutes = [
  { path: "/home", component: Home },
  { path: "/", component: Home },
  { path: "/about", component: About },
  { path: "/service", component: ServiceDetail },
  { path: "/contact", component: Contact },
  { path: "/signin", component: Signin },
  { path: "/signup", component: Signup },
  { path: "/activate/:email/:password", component: Activate },
  { path: "/personaldetail", component: PersonalDetail },
  { path: "/familytreesurvey", component: Familytreesurvey }
];

export { authProtectedRoutes, publicRoutes, authProtectedAdminRoutes };
