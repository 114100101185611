import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { familyTreeSelector } from "../store/familyTree/familyTree.model";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  postPersDataRequest,
  userDataRecRequest,
} from "../store/familyTree/familyTree.action";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { personalDataValidator } from "../validators/Validators";
import { useNavigate } from "react-router-dom";
import {
  getlocationDataRequest,
  settingSelector,
} from "../store/settings/settings.action";
import { selectDefault } from "../utils/Constants";
import { Alert } from "@mui/material";
const PersonalDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = localStorage.getItem("isAuthenticated");

  /* Selectors */
  const { userInfo, succesCreate,treeMessage } = useSelector(familyTreeSelector);
  const { getLocData } = useSelector(settingSelector);
  console.log(treeMessage, "yguhnjk")
  /* States */
  const [open, setOpen] = useState(false);
  /* UserForm */
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(personalDataValidator),
  });

  /* Function */
  const submitData = (formData) => {
    formData.name = userInfo?.name;
    dispatch(postPersDataRequest(formData));
  
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    navigate("/settings")
  };
  /* UseState */
  useEffect(() => {
    setValue("locations", selectDefault);
    dispatch(getlocationDataRequest());
    dispatch(userDataRecRequest({}));
    if(isAuth){
    if (succesCreate.message === "Added Succesfully") {
      navigate("/tree");
  }
}
if(treeMessage=="Cannot create geo details, It seems like already created"){
  setOpen(true);
}
  }, [succesCreate,treeMessage]);

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div>
   <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          It seems like already created
        </Alert>
      </Snackbar>
      <div class="container-fluid">
        <div class="row row-height">
          {/* <div class="col-xl-4 col-lg-4 content-left">
            <div class="content-left-wrapper">
              <div id="social">
                <ul>
                  <li>
                    <a href="#0">
                      <i class="bi bi-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-twitter-x"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-tiktok"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="copy">© 2024 Nanyar</div>
            </div>
          </div> */}
          {/* <!-- /content-left --> */}
          <div class="col-xl-8 col-lg-8 content-right " id="start">
            <Container>
              <div className="d-flex res-just">
                <Form onSubmit={handleSubmit(submitData)}>
                  <div>
                    <h2>Start With YourSelf</h2>
                    <p>Personal Info</p>
                  </div>
                  <Col lg={12}>
                    <Form.Label htmlFor="name">Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name "
                      value={userInfo?.name}
                      readOnly
                    />
                  </Col>
                  <Col lg={12}>
                    <Form.Label htmlFor="dob">Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Date of Birth"
                      {...register("dob")}
                    />
                    {errors?.dob && (
                      <p className="text-danger">{errors?.dob?.message}</p>
                    )}
                  </Col>
                  <Col lg={12}>
                    <Form.Label htmlFor="gender">Gender</Form.Label>

                    <Form.Control as="select" {...register("gender")}>
                      <option value="">Select Gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </Form.Control>
                    {errors?.gender && (
                      <p className="text-danger">{errors?.gender?.message}</p>
                    )}
                  </Col>
                  <Col lg={12}>
                    <Form.Label htmlFor="location">Location</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder="Location"
                      {...register("locations")}
                    >
                      <option disabled value={selectDefault}>
                        SELECT
                      </option>
                      {getLocData?.map((ele, ind) => {
                        return <option value={ele.id}>{ele.name}</option>;
                      })}
                    </Form.Control>
                    {errors?.locations && (
                      <p className="text-danger">
                        {errors?.locations?.message}
                      </p>
                    )}
                  </Col>
                  <Col lg={12} className=" mt-3">
                    <Button type="submit">Submit</Button>
                  </Col>
                </Form>
              </div>
            </Container>
          </div>
          {/* <!-- /content-right--> */}
        </div>
        {/* <!-- /row--> */}
      </div>
    </div>
  );
};

export default PersonalDetail;
