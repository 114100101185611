import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { signInValidator } from "../validators/Validators";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

/** Internal Imports */
import { loginUserRequest } from "../store/user/user.action";
import { userSelector } from "../store/user/user.model";
import "../assets/css/bootstrap.min.css";
import "../assets/css/menu.css";
import "../assets/css/style.css";
import "../assets/css/vendors.css";
import { decryptdata } from "../utils/encrypt&decrypt";

const Activate = () => {
  /** React Component Functions */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email, password } = useParams();
  console.log(email, password, "orgheibgijvn  email, password ");

  console.log(email, 234567);
  const [validated, setValidated] = useState(false);
  const [Loginerror, setLoginerror] = useState(false);
  const isAuth = localStorage.getItem("isAuthenticated");

  /** Selectors */
  const { signinUser, signinisLoading } = useSelector(userSelector);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm();

  const hanldeSignin = () => {
    console.log(email, "UIgf fv uhu ertyhb");
    const mail = decryptdata(
      email.replaceAll("--=)", "/"),
      "cc92472?9^$@%#%83xy7872#26^#@"
    );
    const pass = decryptdata(
      password.replaceAll("--=)", "/"),
      "cc92472?9^$@%#%83xy7872#26^#@"
    );
    let formData = {};
    formData.email = mail;
    formData.password = pass;
    formData.login_status = 1;
    dispatch(loginUserRequest(formData));
  };
  useEffect(() => {
    if (isAuth) {
      Swal.fire({
        title: "Logged in Successfully!",
        icon: "success",
      });
      // dispatch(clearData());
      navigate("/personaldetail");
    }
    console.log(signinUser, 48484);
  }, [signinisLoading]);

  return (
    <>
      <div class="container-fluid">
        <div class="row row-height">
          <div class="col-xl-4 col-lg-4 content-left">
            <div class="content-left-wrapper">
              {/* <!-- <a href="index.html" id="logo"><img src="img/logo.png" alt="" width="45" height="45"></a> --> */}
              <div id="social">
                <ul>
                  <li>
                    <a href="#0">
                      <i class="bi bi-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-twitter-x"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-tiktok"></i>
                    </a>
                  </li>
                </ul>
              </div>
              {/* <!-- /social --> */}

              <div class="login_section">
                <h2 class="section_title">Activate your Account</h2>
                <form
                  className="tooltip-end-bottom"
                  onSubmit={handleSubmit(hanldeSignin)}
                >
                  <div class="submit_btn">
                    <button class="btn_1 rounded yellow" type="submit">
                      Activate
                    </button>
                  </div>
                </form>
              </div>
              <div class="copy">© 2024 Nanyar</div>
            </div>
            {/* <!-- /content-left-wrapper --> */}
          </div>
          {/* <!-- /content-left --> */}
          <div class="col-xl-8 col-lg-8 content-right bg_ad" id="start">
            <div class="background"></div>
          </div>
          {/* <!-- /content-right--> */}
        </div>
        {/* <!-- /row--> */}
      </div>
      {/* <!-- /container-fluid --> */}
    </>
  );
};

export default Activate;
