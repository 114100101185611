import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";
const parentApi = "/family_tree";

export const getTree = ({ query }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `${parentApi}?query=` + query, Http.getAuthToken());
};

export const createTree = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + `${parentApi}/add_member`,
    payload,
    Http.getAuthToken()
  );
};

export const relationList = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `${parentApi}/relation_list`, Http.getAuthToken());
};

export const relativeList = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `${parentApi}/relatives`, Http.getAuthToken());
};

export const userDataList = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `${parentApi}/user-details`, Http.getAuthToken());
};

export const persDataList = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + `${parentApi}/create-user-geo`,
    payload,
    Http.getAuthToken()
  );
};
export const getpersDataList = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `${parentApi}/check-details`, Http.getAuthToken());
};
