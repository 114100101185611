// import { SiLinktree } from "react-icons/si";
// import { FaLocationDot, FaUsers } from "react-icons/fa6";
// import { TbBinaryTree } from "react-icons/tb";
// import { MdOutlineDirectionsRun } from "react-icons/md";
// import { GiTombstone } from "react-icons/gi";
// import React, { useEffect, useState } from "react";
// import { Card } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { adminSelector } from "../store/admin/admin.model";
// import { getUserDashboardRequest, getUserStatusRequest } from "../store/admin/admin.action";
// import { BarChart } from "@mui/x-charts/BarChart";
// import dayjs from "dayjs";
// const Dashboard = () => {
//   const dispatch = useDispatch();

//   const { statusData,getDashData } = useSelector(adminSelector);
//   console.log(getDashData, "uhjdfd33nkjj");
//   const [filter, setFilter] = useState("month")
//   const [selectedYear, setSelectedYear] = useState(dayjs().year());
//   const getMonthNames = () => [
//     "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
//   ];

//   const getYearRange = () => {
//     const currentYear = new Date().getFullYear();
//     const startYear = 2020; 
//     return Array.from({ length: currentYear - startYear + 5 }, (_, i) => (startYear + i).toString());
//   };
//   const transformData = (data, filterType) => {
//     if (filterType === "month") {
//       const months = getMonthNames();
//       return months.map(month => {
//         const entry = data.find(d => d.label === month);
//         return entry ? entry.count : 0;
//       });
//     } else if (filterType === "year") {
//       const years = getYearRange();
//       return years.map(year => {
//         const entry = data.find(d => d.label === year);
//         return entry ? entry.count : 0;
//       });
//     }
//     return [];
//   };

// const xAxisData = filter === "month" ? getMonthNames() :
//    getYearRange() ;

// const barChartData = transformData(getDashData,filter);
//   useEffect(() => {
//     dispatch(getUserStatusRequest());
//     const payload={
//       query:selectedYear
//     }
//     dispatch(getUserDashboardRequest(payload))
//   }, [filter,selectedYear]);
//   return (
//     <div>
//       <div className="mt-5">
//         <h2>
//           Hello, <span className="adminheading"> Admin</span>
//         </h2>
//       </div>

//       <Card>
//         <div className="row g-5 py-5 px-5 row-cols-1 row-cols-lg-3 ">
//           <div className="col d-flex align-items-start">
//             <div className="rounded-circle text-body-emphasis iconscls">
//               <FaLocationDot size={30} />
//             </div>
//             <div className="paradiv">
//               <p className="paras"> Location</p>
//               <p className="paras1">{statusData?.locations} +Location</p>
//             </div>
//           </div>
//           <div className="vi"></div>
//           <div className="col d-flex align-items-start">
//             <div className="rounded-circle text-body-emphasis iconscls">
//               <TbBinaryTree size={30} />
//             </div>
//             <div className="paradiv">
//               <p className="paras">Genology Details</p>
//               <p className="paras1">{statusData?.geonology_details} details</p>
//             </div>
//             <div className="vi2"></div>
//           </div>
//           <div className="col d-flex align-items-start">
//             <div className="rounded-circle text-body-emphasis iconscls">
//               <SiLinktree size={30} />
//             </div>
//             <div className="paradiv">
//               <p className="paras">Family Tree</p>
//               <p className="paras1">
//                 {" "}
//                 {statusData?.family_tree} family details
//               </p>
//             </div>
//           </div>
//         </div>
//       </Card>

//       <Card className="mt-5">
//         <div className="row g-4 py-5 px-5 row-cols-1 row-cols-lg-3">
//           <div className="col d-flex align-items-start">
//             <div className="rounded-circle text-body-emphasis iconscls">
//               <FaUsers size={30} />
//             </div>
//             <div className="paradiv">
//               <p className="paras"> Users</p>
//               <p className="paras1"> {statusData?.users} users</p>
//             </div>
//             <div className="vi"></div>
//           </div>
//           <div className="col d-flex align-items-start">
//             <div className="rounded-circle text-body-emphasis iconscls">
//               <MdOutlineDirectionsRun size={30} />
//             </div>
//             <div className="paradiv">
//               <p className="paras">Alive</p>
//               <p className="paras1">{statusData?.alive_data} alive people</p>
//             </div>
//             <div className="vi2"></div>
//           </div>
//           <div className="col d-flex align-items-start">
//             <div className="rounded-circle text-body-emphasis iconscls">
//               <GiTombstone size={30} />
//             </div>
//             <div className="paradiv">
//               <p className="paras">Death</p>
//               <p className="paras1"> {statusData?.died_data} Died</p>
//             </div>
//           </div>
//         </div>
//       </Card>

//       <Card className="mt-5">
//         <div>
//           <h4>Genology Details</h4>
//         </div>
//         <div className="row justify-content-end">
//           <div className="col-2">
//             <label htmlFor="filterSelect">Select Filter: </label>
//             <select
//               id="filterSelect"
//               value={filter}
//               onChange={(e) => setFilter(e.target.value)}
//               className="form-select"
//             >
//               <option value="month">Monthly</option>
//               <option value="year">Yearly</option>
//             </select>
//           </div>
//           {filter === "month" && (
//             <div className="col-2 mt-2">
//               <label htmlFor="yearSelect">Select Year: </label>
//               <select
//                 id="yearSelect"
//                 value={selectedYear}
//                 onChange={(e) => setSelectedYear(Number(e.target.value))}
//                 className="form-select"
//               >
//                 {getYearRange().map(year => (
//                   <option key={year} value={year}>{year}</option>
//                 ))}
//               </select>
//             </div>
//           )}
//         </div>
//          <BarChart
//         series={[
//             { data: barChartData },
//         ]}
//         height={290}
//         xAxis={[
//             {
//                 data: xAxisData,
//                 scaleType: "band",
//             },
//         ]}
//         margin={{ top: 20, bottom: 20, left: 60, right: 40 }}
//     />
//       </Card>
//     </div>
//   );
// };

// export default Dashboard;
import { SiLinktree } from "react-icons/si";
import { FaLocationDot, FaUsers } from "react-icons/fa6";
import { TbBinaryTree } from "react-icons/tb";
import { MdOutlineDirectionsRun } from "react-icons/md";
import { GiTombstone } from "react-icons/gi";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { adminSelector } from "../store/admin/admin.model";
import { getUserDashboardRequest, getUserStatusRequest } from "../store/admin/admin.action";
import { BarChart } from "@mui/x-charts/BarChart";
import dayjs from "dayjs";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { statusData, getDashData } = useSelector(adminSelector);
console.log(getDashData, "uyghmbn bn")
  const [filter, setFilter] = useState("month");
  const [selectedYear, setSelectedYear] = useState(dayjs().year());

  const getMonthNames = () => [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const getYearRange = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2021;
    return Array.from({ length: currentYear - startYear + 5 }, (_, i) => (startYear + i).toString());
  };

  const transformData = (data, filterType) => {
    /* console.log(data,filter, "5rftgvhmn ")
    if (filterType == "month") { */
      const months = getMonthNames();
      return months.map(month => {
        const entry = data.find(d => d.label === month);
        return entry ? entry.count : 0;
      });
    // }
    // } else if (filterType === "year") {
    //   const years = getYearRange();
    //   return years.map(year => {
    //     const entry = data.find(d => d.label === year);
    //     return entry ? entry.count : 0;
    //   });
    // }
    // return [];
  };

  const xAxisData = filter === "month" ? getMonthNames() : getYearRange();
  const barChartData = transformData(getDashData, filter);
console.log(barChartData, "45rtfgbh")
  useEffect(() => {
    dispatch(getUserStatusRequest());
    const payload = { query: selectedYear,
      month:filter
     };
    dispatch(getUserDashboardRequest(payload));
  }, [ selectedYear]);

  return (
    <div>
      <div className="mt-5">
        <h2>
          Hello, <span className="adminheading"> Admin</span>
        </h2>
      </div>

      <Card>
        <div className="row g-5 py-5 px-5 row-cols-1 row-cols-lg-3">
          <div className="col d-flex align-items-start">
            <div className="rounded-circle text-body-emphasis iconscls">
              <FaLocationDot size={30} />
            </div>
            <div className="paradiv">
              <p className="paras"> Location</p>
              <p className="paras1">{statusData?.locations} +Location</p>
            </div>
          </div>
          <div className="vi"></div>
          <div className="col d-flex align-items-start">
            <div className="rounded-circle text-body-emphasis iconscls">
              <TbBinaryTree size={30} />
            </div>
            <div className="paradiv">
              <p className="paras">Genology Details</p>
              <p className="paras1">{statusData?.geonology_details} details</p>
            </div>
            <div className="vi2"></div>
          </div>
          <div className="col d-flex align-items-start">
            <div className="rounded-circle text-body-emphasis iconscls">
              <SiLinktree size={30} />
            </div>
            <div className="paradiv">
              <p className="paras">Family Tree</p>
              <p className="paras1">
                {statusData?.family_tree} family details
              </p>
            </div>
          </div>
        </div>
      </Card>

      <Card className="mt-5">
        <div className="row g-4 py-5 px-5 row-cols-1 row-cols-lg-3">
          <div className="col d-flex align-items-start">
            <div className="rounded-circle text-body-emphasis iconscls">
              <FaUsers size={30} />
            </div>
            <div className="paradiv">
              <p className="paras"> Users</p>
              <p className="paras1"> {statusData?.users} users</p>
            </div>
            <div className="vi"></div>
          </div>
          <div className="col d-flex align-items-start">
            <div className="rounded-circle text-body-emphasis iconscls">
              <MdOutlineDirectionsRun size={30} />
            </div>
            <div className="paradiv">
              <p className="paras">Alive</p>
              <p className="paras1">{statusData?.alive_data} alive people</p>
            </div>
            <div className="vi2"></div>
          </div>
          <div className="col d-flex align-items-start">
            <div className="rounded-circle text-body-emphasis iconscls">
              <GiTombstone size={30} />
            </div>
            <div className="paradiv">
              <p className="paras">Death</p>
              <p className="paras1"> {statusData?.died_data} Died</p>
            </div>
          </div>
        </div>
      </Card>

      <Card className="mt-5">
        <div>
          <h4>Genology Details</h4>
        </div>
        <div className="row justify-content-end">
          <div className="col-2">
            <label htmlFor="filterSelect">Default Filter: </label>
            <input
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="form-select"
              disabled
              placeholder="Monthly"
            />
              {/* <option value="month">Monthly</option> */}
              {/* <option value="year">Yearly</option> */}
          </div>
          {filter === "month" && (
            <div className="col-2 ">
              <label htmlFor="yearSelect">Select Year: </label>
              <select
                id="yearSelect"
                value={selectedYear}
                onChange={(e) => setSelectedYear(Number(e.target.value))}
                className="form-select"
              >
                {getYearRange().map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
          )}
        </div>
        <BarChart
          series={[
            { data: barChartData },
          ]}
          height={290}
          xAxis={[
            {
              data: xAxisData,
              scaleType: "band",
            },
          ]}
          margin={{ top: 20, bottom: 20, left: 60, right: 40 }}
        />
      </Card>
    </div>
  );
};

export default Dashboard;

