const namespace = "@@family_tree";

export const TreeActionItems = {
  GETTREENODE_SUCESS: `${namespace}/GETTREENODE_SUCESS`,
  GETTREENODE_REQUEST: `${namespace}/GETTREENODE_REQUEST`,
  GETTREENODE_FAILURE: `${namespace}/GETTREENODE_FAILURE`,

  ADDMEMBER_REQUEST: `${namespace}/ADDMEMBER_REQUEST`,
  ADDMEMBER_SUCCESS: `${namespace}/ADDMEMBER_SUCCESS`,
  ADDMEMBER_FAILURE: `${namespace}/ADDMEMBER_FAILURE`,

  RELATIONS_REQUEST: `${namespace}/RELATIONS_REQUEST`,
  RELATIONS_SUCCESS: `${namespace}/RELATIONS_SUCCESS`,
  RELATIONS_FAILURE: `${namespace}/RELATIONS_FAILURE`,

  RELATIVES_REQUEST: `${namespace}/RELATIVES_REQUEST`,
  RELATIVES_SUCCESS: `${namespace}/RELATIVES_SUCCESS`,
  RELATIVES_FAILURE: `${namespace}/RELATIVES_FAILURE`,

  USERDATAGET_REQUEST: `${namespace}/USERDATAGET_REQUEST`,
  USERDATAGET_SUCCESS: `${namespace}/USERDATAGET_SUCCESS`,
  USERDATAGET_FAILURE: `${namespace}/USERDATAGET_FAILURE`,

  PERSONALDATAPOST_REQUEST: `${namespace}/PERSONALDATAPOST_REQUEST`,
  PERSONALDATAPOST_SUCCESS: `${namespace}/PERSONALDATAPOST_SUCCESS`,
  PERSONALDATAPOST_FAILURE: `${namespace}/PERSONALDATAPOST_FAILURE`,

  PERSONALDATAGET_REQUEST: `${namespace}/PERSONALDATAGET_REQUEST`,
  PERSONALDATAGET_SUCCESS: `${namespace}/PERSONALDATAGET_SUCCESS`,
  PERSONALDATAGET_FAILURE: `${namespace}/PERSONALDATAGET_FAILURE`,

  MEMBER_CLEAR: `${namespace}/MEMBER_CLEAR`,
};

export const treeInitialState = {
  treeLoading: false,
  treeData: [],
  addMemberLoading: false,
  relationData: [],
  relativesData: [],
  relationLoading: false,
  relativesLoad: false,
  userDataLoad: false,
  userInfo: [],
  perDatapostLoading: false,
  succesCreate: {},
  getCreateLoading: false,
  getCreateData: [],
  treeMessage: "",
};

export const familyTreeSelector = (state) => state.TreeReducer;
