export const DIMENSION = {
  Desktop: "desktop",
  Tablet: "tablet",
  Mobile: "mobile",
};

export const LAYOUT = {
  Fluid: "fluid",
  Boxed: "boxed",
};

export const RADIUS = {
  Rounded: "rounded",
  Standard: "standard",
  Flat: "flat",
};

export const THEME_COLOR = {
  LightBlue: "light-blue",
  DarkBlue: "dark-blue",
  LightRed: "light-red",
  DarkRed: "dark-red",
  LightGreen: "light-green",
  DarkGreen: "dark-green",
  LightPurple: "light-purple",
  DarkPurple: "dark-purple",
  LightPink: "light-pink",
  DarkPink: "dark-pink",
};

export const NAV_COLOR = {
  Default: "default",
  Light: "light",
  Dark: "dark",
};
export const MENU_PLACEMENT = {
  Vertical: "vertical",
  Horizontal: "horizontal",
};
export const MENU_BEHAVIOUR = {
  Pinned: "pinned",
  Unpinned: "unpinned",
};

export const USER_ROLE = {
  Admin: "admin",
  Editor: "editor",
};

export const siblingNodeIds = [3, 4, 5, 6];

export const findSiblingNode = (data) => {
  return data.filter((ele) => siblingNodeIds.includes(ele.relation));
};

export const API_STATUS = Object.freeze({
  PENDING: "Pending",
  REJECTED: "Rejected",
  FULFILLED: "Fulfilled",
  NETWORK_ERROR: "Network Error",
});

export const selectDefault = "ufybwcuf&&";

export const QUESTION_GROUP = Object.freeze({
  1: "First",
  2: "Second",
});

export const excludedMessages = ["Current Group Updated", ""];

export const  actionData = Object.freeze({
  0: "Created",
  1: "Action taken",
  2: "Completed",
  3: "Rejected",
  4: "Cancelled",
});
