import { action } from "typesafe-actions";
import { TreeActionItems } from "./familyTree.model";

export const getTreeSuccess = (payload) =>
  action(TreeActionItems.GETTREENODE_SUCESS, payload);

export const getTreeRequest = (req) =>
  action(TreeActionItems.GETTREENODE_REQUEST, req);

export const getTreeFailure = (error) =>
  action(TreeActionItems.GETTREENODE_FAILURE, error);

export const addMemberRequest = (req) =>
  action(TreeActionItems.ADDMEMBER_REQUEST, req);

export const addMemberSuccess = (payload) =>
  action(TreeActionItems.ADDMEMBER_SUCCESS, payload);

export const addMemberFailure = (error) =>
  action(TreeActionItems.ADDMEMBER_FAILURE, error);

export const relationRequest = (req) =>
  action(TreeActionItems.RELATIONS_REQUEST, req);

export const relationSuccess = (payload) =>
  action(TreeActionItems.RELATIONS_SUCCESS, payload);

export const relationFailure = (error) =>
  action(TreeActionItems.RELATIONS_FAILURE, error);

export const relativesRequest = (req) =>
  action(TreeActionItems.RELATIVES_REQUEST, req);

export const relativesSuccess = (payload) =>
  action(TreeActionItems.RELATIVES_SUCCESS, payload);

export const relativesFailure = (err) =>
  action(TreeActionItems.RELATIVES_FAILURE, err);

export const userDataRecRequest = (req) =>
  action(TreeActionItems.USERDATAGET_REQUEST, req);

export const userDataRecSuccess = (payload) =>
  action(TreeActionItems.USERDATAGET_SUCCESS, payload);

export const userDataRecFailure = (err) =>
  action(TreeActionItems.USERDATAGET_FAILURE, err);
//
export const postPersDataRequest = (req) =>
  action(TreeActionItems.PERSONALDATAPOST_REQUEST, req);

export const postPersDataSuccess = (payload) =>
  action(TreeActionItems.PERSONALDATAPOST_SUCCESS, payload);

export const postPersDataFailure = (err) =>
  action(TreeActionItems.PERSONALDATAPOST_FAILURE, err);

export const getPersDataRequest = (req) =>
  action(TreeActionItems.PERSONALDATAGET_REQUEST, req);

export const getPersDataSuccess = (payload) =>
  action(TreeActionItems.PERSONALDATAGET_SUCCESS, payload);

export const getPersDataFailure = (err) =>
  action(TreeActionItems.PERSONALDATAGET_FAILURE, err);

export const clearNode = err => action(TreeActionItems.MEMBER_CLEAR,err)

