import { Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";
import famroot from "../../src/assets/img/famroot.jpg";
import { BiStreetView } from "react-icons/bi";
import { LuFile, LuFiles } from "react-icons/lu";
import { MdConnectWithoutContact } from "react-icons/md";
import gift from "../../src/assets/img/gifts.jpg";
import remainder from "../../src/assets/img/remainders.jpg";
import birth from "../../src/assets/img/birth.jpg";
import caring from "../../src/assets/img/caring.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorClosed, faDove, faMap, faTree, faUtensils } from "@fortawesome/free-solid-svg-icons";
import vacation from "../../src/assets/img/vacation.jpg";
import invitation from "../../src/assets/img/invitation.jpg";
import famtree from "../../src/assets/img/famtree.jpg";
import { useNavigate } from 'react-router-dom';

function ServiceDetail(){

    const navigate = useNavigate();

  const handleClick = () => {
    navigate('/familytreesurvey');
  };
    return(
        <>
        <Header/>

            <section className="page-bg">
                <Container>
                    <div className="bg-txt">
                        <h2>Services</h2>
                    </div>
                </Container>
            </section>

            <section className="fam">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="famroot">
                                <img src={famroot}/>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="famroot-content">
                                <span>Discover Your Family's Roots</span>
                                <h2>Uncover the History of Your Ancestors with Us</h2>
                                <p>Our tools allow you to explore centuries of history, connecting you to the places and people that form your heritage. Delve into historical records, trace the paths of your forebears, and piece together the intricate puzzle of your ancestry. Whether you're looking to connect with long-lost relatives or learn more about the lives of those who came before you, our platform is here to guide you every step of the way.</p>
                                <p>Start your journey today and discover the rich tapestry of your family's past. Unearth the stories that make you who you are, and leave a lasting legacy for future generations to explore.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="additional">
                        <Col lg={4} md={6} sm={12}>
                            <div className="fam-service">
                                <div className="ihead">
                                <BiStreetView
                                    className="detail-icon fs-1"
                                />
                                    <h4>Explore Your Family Tree</h4>
                                </div>
                                <div>
                                    <p>Trace your lineage and uncover the branches of your family
                                    tree with our comprehensive tools.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="fam-service">
                                <div className="ihead">
                                    <LuFiles className="detail-icon fs-1" />
                                    <h4>Discover Historical Records</h4>
                                </div>
                                <div>
                                    <p>Access a vast collection of historical documents to learn about your ancestors' lives and stories.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="fam-service resgap">
                                <div className="ihead">
                                    <MdConnectWithoutContact
                                    className="detail-icon fs-1"
                                    />
                                    <h4>Connect with Relatives</h4>
                                </div>
                                <div>
                                    <p>Find and connect with distant relatives to share memories
                                    and build a richer family history.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="birth">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div>
                                <span>Never Miss a Birthday Again</span>
                                <h2>Celebrate Your Loved Ones' Birthdays with Us</h2>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div>
                                <p>We understand the importance of family and the joy that comes with celebrating birthdays. Our Birthday Reminders feature ensures you never miss an important day, keeping you connected with your loved ones across generations.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="additional">
                        <Col lg={4} md={6} sm={12}>
                            <div className="image-birth">
                                <img src={birth}/>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="birth-cards">
                                <img src={gift}/>
                                <div className="space-card">
                                    <h4>Send Thoughtful Gifts</h4>
                                    <p>Our platform makes it easy to select and send thoughtful gifts that resonate with your family’s unique history and values. From personalized keepsakes that honor your shared heritage to curated selections that reflect individual tastes, we help you find the right gift for every occasion.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="birth-cards resgap">
                                <img src={remainder}/>
                                <div className="space-card">
                                    <h4>Automatic Reminders</h4>
                                    <p>We've made it simple to stay on top of your family's special moments with our automatic reminders.Whether it's a birthday, anniversary, or another significant event, our platform ensures you're always in the loop. Receive timely notifications so you can plan ahead and never miss an opportunity to celebrate.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="fam">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="famroot">
                                <img src={caring}/>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="famroot-content">
                                <span>with unwavering respect</span>
                                <h2>Honoring Loved Ones with Care and Respect</h2>
                                <p>In the journey of tracing your family history, it's essential to honor the memories of those who came before us. Our platform is dedicated to preserving the legacies of your loved ones with the utmost care and respect. We provide you with the tools to create lasting tributes, ensuring that their stories are told with dignity and love.</p>
                                <p>Whether it's through sharing cherished memories, uploading meaningful photos, or documenting their life achievements, we help you keep their memory alive for future generations. Together, let's celebrate the lives of those who shaped our past with the honor they deserve.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="additional care">
                        <Col lg={4} md={6} sm={12}>
                            <div className="fam-service">
                                <div className="ihead">
                                    <FontAwesomeIcon
                                    className="detail-icon"
                                    icon={faDoorClosed}
                                    />
                                    <h4>Cemetery Cleaning Services</h4>
                                </div>
                                <div>
                                    <p>Preserving peace through meticulous cemetery care</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="fam-service">
                                <div className="ihead">
                                    <FontAwesomeIcon
                                    className="detail-icon"
                                    icon={faUtensils}
                                    />
                                    <h4>Compassionate Food Services</h4>
                                </div>
                                <div>
                                <p>
                                    Professional staff to manage setup, service, and cleanup
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="fam-service">
                                <div className="ihead">
                                    <FontAwesomeIcon className="detail-icon" icon={faMap} />
                                    <h4>Personalized Flyers and Banners</h4>
                                </div>
                                <div>
                                    <p>Create memorable tributes with customizable designs</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="fam-service famgap">
                                <div className="ihead">
                                    <FontAwesomeIcon className="detail-icon" icon={faDove} />
                                    <h4>Funeral Arrangements</h4>
                                </div>
                                <div>
                                <p>
                                    We handle Pooja and funeral arrangements with care and
                                    respect, honoring your traditions and wishes.
                                </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="fam-service famgap">
                                <div className="ihead">
                                    <FontAwesomeIcon className="detail-icon" icon={faTree} />
                                    <h4>Find your ancestors</h4>
                                </div>
                                <div>
                                    <p>
                                    Bring to life your family’s history by exploring the lives
                                    of those that came before you with the help of Nanyaar
                                    map, Ancestors search and Nanyaar matches.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="birth">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div>
                                <span>Thoughtful Marriage Gifts</span>
                                <h2>Celebrate your Love and Heritage with Us</h2>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div>
                                <p>Set the tone for your special day with exquisite wedding invitations that reflect your shared heritage and story. Choose from a variety of customizable designs that beautifully incorporate your family history.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="additional vacate">
                        <Col lg={4} md={6} sm={12}>
                            <div className="birth-cards">
                                <img src={vacation}/>
                                <div className="space-card">
                                    <h4>Dream Vacation Planning</h4>
                                    <p>Turn your travel dreams into reality with our personalized vacation planning services. We take the stress out of organizing your perfect getaway by handling every detail, from flights and accommodations to curated experiences that match your interests, our expert planners work with you to design a trip.</p>
                                </div>
                            </div>                        
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="birth-cards">
                                <img src={gift}/>
                                <div className="space-card">
                                    <h4>Send Thoughtful Gifts</h4>
                                    <p>Our platform makes it easy to select and send thoughtful gifts that resonate with your family’s unique history and values. From personalized keepsakes that honor your shared heritage to curated selections that reflect individual tastes, we help you find the right gift for every occasion.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="birth-cards resgap">
                                <img src={invitation}/>
                                <div className="space-card">
                                    <h4>Elegant Invitation Designs</h4>
                                    <p>Set the tone for your special occasion with our exquisite invitation designs. Each invitation is thoughtfully crafted to reflect the style and sentiment of your event, whether it's a wedding, a memorial service, or a family gathering. Our designs blend timeless elegance with personalized touches.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="famtree">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="famroot">
                                <img src={famtree}/>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="famroot-content">
                                <span>Discover Your Heritage</span>
                                <h2>Connecting Generations Across Time</h2>
                                <p>Exploring genealogy in India is not just about discovering ancestors but also about
                                connecting with cultural, religious, and social heritage, offering a deep insight into the
                                country’s diverse and complex history.</p>
                                <p>Indian genealogy is deeply intertwined with the
                                country’s social, religious, and cultural systems, often preserved through oral traditions,
                                written records, and religious texts.</p>
                                <button onClick={handleClick}>Read More</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        <Footer/>
        </>
    );
}
export default ServiceDetail;