import '../assets/css/homepage.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import naanyarlogo from '../assets/img/naanyarlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faSquareFacebook, faSquareInstagram, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';
import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/responsive.css';
function Footer() {
    return (
        <>
            <div className='footer-s'>
                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={3} >
                            <div className='footer-logo'>
                                <img src={naanyarlogo} />
                                <p>
                                    Stay connected and get the latest updates, tips, and resources for your genealogy journey.
                                </p>
                                <h3>Follow Us on</h3>
                                <div className='social-icons'>
                                    <ul>
                                        <li><FontAwesomeIcon icon={faSquareInstagram} /> </li>
                                        <li><FontAwesomeIcon icon={faSquareFacebook} /> </li>
                                        <li><FontAwesomeIcon icon={faSquareXTwitter} /> </li>
                                        <li><FontAwesomeIcon icon={faLinkedin} /> </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} sm={12} lg={3}>
                            <div className='footer-links'>
                                <h3>Useful Links</h3>
                                <ul>
                                    <li><Link to={'/home'}>Home</Link></li>
                                    <li><Link to={'/about'}>About</Link></li>
                                    <li><Link to={'/service'}>Services</Link></li>
                                    <li><Link to={'/contact'}>Contact</Link></li>
                                    <li><Link>Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} sm={12} lg={3}>
                            <div className='footer-links'>
                                <h3>Useful Links</h3>
                                <ul>
                                    <li><Link>Genealogy Research</Link></li>
                                    <li><Link>DNA Testing</Link></li>
                                    <li><Link>Family Tree Building</Link></li>
                                    <li><Link>Cemetery Cleaning</Link></li>
                                    <li><Link>Pooja and Funeral Arrangements</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} sm={12} lg={3}>
                            <div className='footer-links'>
                                <h3>Address</h3>
                                <ul>
                                    <li><Link><FontAwesomeIcon className='foot-i' icon={faPhone} />+91 70943 69149</Link> </li>
                                    <li><Link><FontAwesomeIcon className='foot-i' icon={faLocationDot} />Gandhipuram, Coimbatore,Tamilnadu 641020</Link> </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={12} lg={12} sm={12}>
                            <div className='text-center'>
                                <p>Copyright © 2024 Naanyar</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
} export default Footer;