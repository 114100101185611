import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

const parentApi = "/family_tree";

export const relativeListData = ({ locations, dobfrom, dobto, page, limit }) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(locations, 7989999);
  return Http.get(
    baseUrl +
      `${parentApi}/relatives?page=` +
      page +
      "&dobfrom=" +
      dobfrom +
      "&dobto=" +
      dobto +
      "&locations=" +
      locations +
      "&limit=" +
      limit,
    Http.getAuthToken()
  );
};

export const createServicesApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + `/services/create-service`, formData, Http.getAuthToken());
};

export const adminUserApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `/user/admins`, Http.getAuthToken());
};

export const getStatusApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `/settings/stats`, Http.getAuthToken());
};

export const getDashBoardApi = (query) => {
  console.log(query, "uyhjklgh")
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `/settings/dashboard_stat?year=`+query?.query
    // +"&month="+query?.month
    ,

     Http.getAuthToken());
};

export const deleteServicesApi = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + `/services/delete-service`, payload, Http.getAuthToken());
};