import { useCallback, useEffect, useMemo, useState } from "react";
import Tree from "react-d3-tree";
import { useDispatch, useSelector } from "react-redux";
import {
  addMemberRequest,
  getTreeRequest,
  relationRequest,
  relativesRequest,
} from "../store/familyTree/familyTree.action";
import { familyTreeSelector } from "../store/familyTree/familyTree.model";
import { Card, CardBody, Col, Form, Row } from "react-bootstrap";
import { addMemberValidator } from "../validators/Validators";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import {
  findSiblingNode,
  selectDefault,
  siblingNodeIds,
} from "../utils/Constants";
import {
  getlocationDataRequest,
  settingSelector,
} from "../store/settings/settings.action";
import { useParams } from "react-router-dom";
import { useCenteredTree } from "./helpers";
import { message } from "../utils/message";
const TreeView = () => {
  const dispatch = useDispatch();
  const [seletedRelation, setSeletedRelate] = useState();
  const [dimensions, translate, containerRef] = useCenteredTree();
  const [clearNode, setClearNode] = useState(false);
  const [clearNodeId, setClearNodeId] = useState("");
  const [clickedNode, setClickedNode] = useState();
  console.log(seletedRelation, "seletedRelation");
  const {
    treeData,
    addMemberLoading,
    relationData,
    relativesData,
    treeLoading,
    treeMessage,
  } = useSelector(familyTreeSelector);
  const { getLocData } = useSelector(settingSelector);
  const { query } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(addMemberValidator),
  });

  const [status, setStatus] = useState("alive");
  const [selectedDate, setSelectedDate] = useState("");
  const [treeNodeData, setTreeNodeData] = useState({});
  console.log(treeNodeData, "treeNodeData ");

  const handleSwitchChange = () => {
    setStatus((prevStatus) => (prevStatus === "alive" ? "dead" : "alive"));
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleNodeClick = (e) => {
    console.log(e, "jidfhiou");
    if (e?.data?.isSibling) {
      setClearNode(true);
      setClearNodeId(e?.data?.id);
    } else {
      setClearNodeId("");
      setClearNode(false);
    }
    setClickedNode(e?.data?.id);
    dispatch(getTreeRequest({ query: e?.data?.id }));
  };

  const handleSiblingClick = (id) => {
    // dispatch(clearNode());
    console.log(id, "jdfdjfbd");
    if (id?.isSiblings) {
      setClearNode(true);
      setClearNodeId(id?.id);
    } else {
      setClearNodeId("");
    }
    dispatch(getTreeRequest({ query: id?.id }));
  };

  const addMembers = (formData) => {
    if (formData.node_id == selectDefault || formData.node_id == "")
      delete formData.node_id;
    if (formData.child_id == selectDefault || formData.child_id == "")
      delete formData.child_id;
    if (formData.parent_id == selectDefault || formData.parent_id == "")
      delete formData.parent_id;
    formData.status = status == "alive" ? 1 : 0;
    console.log(formData, "jsdfkds;");
    if (formData.dod === "") {
      delete formData.dod;
    }
    dispatch(addMemberRequest(formData));
  };

  const setDefaults = () => {
    setValue("node_id", selectDefault);
    setValue("parent_id", selectDefault);
    setValue("child_id", selectDefault);
    setValue("gender", selectDefault);
    setValue("locations", selectDefault);
  };

  // useEffect(() => {
  //   if (addMemberLoading) {
  //     dispatch(getTreeRequest({ query: "" }));
  //     reset();
  //     setStatus("alive");
  //     setDefaults();
  //   }
  //   // dispatch(relationRequest());
  // }, [addMemberLoading]);

  useEffect(() => {
    dispatch(getlocationDataRequest());
    dispatch(getTreeRequest({ query: query ?? "" }));
    setDefaults();
  }, [query]);

  // const siblingNode = useMemo(() => {
  //   return findSiblingNode(treeData);
  // }, [treeData]);

  useEffect(() => {
    var dataBind = clearNode ? {} : treeNodeData;
    if (Object.keys(dataBind)?.length > 0) {
      const index = dataBind?.children?.findIndex(
        (data) => data.id == treeData?.id
      );
      if (index != -1) {
        if (dataBind.children) dataBind.children[index] = treeData;
      } else {
        dataBind = treeData;
      }
    } else {
      dataBind = treeData;
    }
    console.log(dataBind, "dataBind", treeData);
    setTreeNodeData(dataBind);
  }, [treeData, treeLoading]);

  useEffect(() => {
    setSeletedRelate(getValues("relation_id"));
  }, [watch("relation_id")]);

  const registerName = useMemo(() => {
    return siblingNodeIds.includes(parseInt(seletedRelation))
      ? "child_id"
      : "parent_id";
  }, [seletedRelation]);

  const TreeComp = useCallback(() => {
    return (
      <>
        <Tree
          // pathFunc={"step"}
          rootNodeClassName="mainParent"
          dimensions={dimensions}
          translate={translate}
          data={treeNodeData}
          onLinkClick={handleNodeClick}
          onNodeClick={handleNodeClick}
        />
      </>
    );
  }, [treeNodeData, treeLoading]);

  useEffect(() => {
    if (treeMessage == message.addMembers) {
      dispatch(getTreeRequest({ query: clearNodeId ?? "" }));
      reset();
      setStatus("alive");
      setDefaults();
    }
    if (treeMessage == message.treeNodeSuccess) {
      dispatch(relationRequest());
      dispatch(relativesRequest());
    }

    if (clearNodeId != "") {
      console.log(clearNodeId,"rfsfsdfds");
      setValue("child_id", clearNodeId);
      setValue("parent_id", clearNodeId);
    }
  }, [treeMessage, clearNodeId]);

  return (
    <>
      <Row>
        <Col md={9} className="p-0">
          <div className="tree" style={{ height: "100vh", width: "100%" }} ref={containerRef}>
            {Object.keys(treeNodeData)?.length > 0 ? <TreeComp /> : <></>}
          </div>
        </Col>
        <Col md={3} className="p-0 tree-wid">
          <Card className="border-0 shadow m-0 h-100">
            <CardBody>
              <div>
                <h4>Add Member</h4>
                <div>
                  <form
                    className="tooltip-end-bottom "
                    onSubmit={handleSubmit(addMembers)}
                  >
                    <div className="form-group">
                      <input
                        type="name"
                        name="name"
                        id="name"
                        className="form-control required"
                        placeholder="Name"
                        {...register("name")}
                      />
                      {errors?.name?.message && (
                        <div>
                          <p className="error text-danger">
                            {errors?.name?.message}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <select
                        className="form-control required"
                        {...register("relation_id")}
                        defaultValue={selectDefault}
                      >
                        <option disabled value={selectDefault}>
                          Relation
                        </option>
                        {relationData?.map((relationships, index) => {
                          return (
                            <>
                              <option
                                id={relationships?.id}
                                value={relationships?.id}
                              >
                                {relationships?.relation_name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {errors?.relation_id?.message && (
                        <div>
                          <p className="error text-danger">
                            {errors?.relation_id?.message}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <select
                        className="form-control required"
                        {...register(`${registerName}`)}
                        // defaultValue={selectDefault}
                      >
                        <option disabled value={selectDefault}>
                          Relatives
                        </option>
                        {relativesData?.map((relative, index) => {
                          console.log(relative, "skldfgiygiy");
                          return (
                            <option
                              id={relative?.id}
                              value={Number(relative?.id)}
                            >
                              {relative?.name}
                            </option>
                          );
                        })}
                      </select>
                      {errors?.child_id?.message && (
                        <div>
                          <p className="error text-danger">
                            {errors?.child_id?.message}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <select
                        className="form-control required"
                        {...register("gender")}
                      >
                        <option disabled value={selectDefault}>
                          Gender
                        </option>

                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </select>
                      {errors?.child_id?.message && (
                        <div>
                          <p className="error text-danger">
                            {errors?.child_id?.message}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="date"
                        name="dob"
                        id="dob"
                        className="form-control required"
                        placeholder="DOB"
                        {...register("dob")}
                      />
                      {errors?.dob?.message && (
                        <div>
                          <p className="error text-danger">
                            {errors?.dob?.message}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="form-group d-flex form-control justify-content-around align-items-center">
                      <div
                        className={status === "alive" ? "selected" : ""}
                        onClick={() => setStatus("alive")}
                      >
                        Alive
                      </div>
                      <Form.Check
                        type="switch"
                        checked={status === "dead"}
                        onChange={handleSwitchChange}
                      />
                      <div
                        className={status === "dead" ? "selected" : ""}
                        onClick={() => setStatus("dead")}
                      >
                        Dead
                      </div>
                    </div>
                    <div className="form-group">
                      {status === "dead" && (
                        <input
                          className="form-control"
                          type="date"
                          placeholder="DOD"
                          {...register("dod")}
                        />
                      )}
                      {errors?.dod?.message && (
                        <div>
                          <p className="error text-danger">
                            {errors?.dod?.message}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <select
                        as="select"
                        name="location"
                        id="location"
                        className="form-control required"
                        placeholder="Location"
                        {...register("locations")}
                      >
                        <option disabled value={selectDefault}>
                          Location
                        </option>
                        {getLocData?.map((ele) => {
                          return <option value={ele.id}>{ele.name}</option>;
                        })}
                      </select>
                      {errors?.locations?.message && (
                        <div>
                          <p className="error text-danger">
                            {errors?.locations?.message}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="submit_btn text-center">
                      <button className="btn_1 rounded yellow" type="submit">
                        Add Member
                      </button>
                    </div>
                  </form>
                  <Card>
                    <CardBody>
                      <h5>Children</h5>
                      <hr />
                      {treeData?.siblings?.map((childs, index) => {
                        return (
                          <Card key={index} className="mt-2">
                            <CardBody
                              onClick={() => handleSiblingClick(childs)}
                            >
                              <h5>{childs?.name}</h5>
                              <div
                              //  className="d-flex justify-content-between"
                              >
                                <b className="text-muted">
                                  {childs?.attributes?.Relation}
                                </b>
                                {" - "}
                                <b className="text-muted">
                                  {dayjs(childs?.attributes?.DOB).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </b>
                              </div>
                            </CardBody>
                          </Card>
                        );
                      })}
                    </CardBody>
                  </Card>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TreeView;
