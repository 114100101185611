// import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { call, put, takeEvery } from "redux-saga/effects";
import Swal from "sweetalert2";
// import { defaultAuth, defaultProvider } from '../../helpers/firebase/config';
import * as AuthActions from "./auth.action";
import { AuthActionTypes } from "./auth.model";
import { signUp, signIn } from "./auth.api";

export function* handleLogin(action) {
  try {
    const response = yield call(signIn, action.payload);
    console.log(response,4854759)
    yield put(AuthActions.loginUserSuccess(response));
  } catch (error) {
    yield put(AuthActions.loginUserFailure(error));
    console.log(error, 576756);
    Swal.fire({
      title: "Login Denied!",
      text: error?.response?.data?.error,
      icon: "error",
    });
  }
}

export function* AuthSaga() {
  yield takeEvery(AuthActionTypes.LOGIN_USER_REQUEST, handleLogin);
}
