import { Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

function Contact(){
    return(
        <>
        <Header/>

        <section className="page-bg">
            <Container>
                <div className="bg-txt">
                    <h2>Contact Us</h2>
                </div>
            </Container>
        </section>

        <section className="contact">
            <Container>
                <Row>
                    <Col md={6} sm={12}>
                        <div className="contact-details">
                            <h2>Start Your Journey With Us</h2>
                            <h3>Visit Us</h3>
                            <p>We'd love to hear from you! Whether you have a question, need assistance, or want to share your genealogical discoveries, the Nanyaar team is here to help.</p>
                            <ul>
                                <p><FontAwesomeIcon icon={faLocationDot} /><li>111/9, Bharathiyar Sathukkam,Akshaya Garden, Arivoli Nagar, Kovai Pudur, Coimbatore - 641042.</li></p>
                                <p><FontAwesomeIcon icon={faPhone} /><li>+91 80565 - 34254</li></p>
                                <p><FontAwesomeIcon icon={faEnvelope} /><li>austintech2022@gmail.com</li></p>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6} sm={12}>
                        <div className="contact-form">
                            <Form>
                                <Form.Group className="mb-3" controlId="formGroupPassword">
                                    <Form.Control type="name" placeholder="Name" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupEmail">
                                    <Form.Control type="email" placeholder="Enter email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupPassword">
                                    <Form.Control type="subject" placeholder="Subject" />
                                </Form.Group>
                                <InputGroup>
                                    <Form.Control as="textarea" placeholder="Message" />
                                </InputGroup>
                                <Button type="submit">Send Message</Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <iframe width="100%" height="560" id="gmap_canvas" src="https://maps.google.com/maps?q=111%2F9%2C+Bharathiyar+Sathukkam%2CAkshaya+Garden%2C+Arivoli+Nagar%2C+Kovai+Pudur%2C+Coimbatore+%E2%80%93+641042.&t=&z=14&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>

        <Footer/>
        </>
    );
}
export default Contact;