import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Form, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { addEventValidator } from "../validators/Validators";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddEventRequest,
  getlocationDataRequest,
  getserviceDataRequest,
  postAddEventRequest,
  postUpdateEventRequest,
  settingSelector,
} from "../store/settings/settings.action";
import { actionData, selectDefault } from "../utils/Constants";
import dayjs from "dayjs";
import { MdCancel } from "react-icons/md";
import { CircularProgress } from "@mui/material";

const Events = () => {
  const dispatch = useDispatch();

  /* Selectors */
  const {
    getServiceDate,
    getEventData,
    postSuccess,
    getLocData,
    updateSucces,
  } = useSelector(settingSelector);
  console.log(
    getServiceDate,
    getEventData,
    postSuccess,
    getLocData,
    "s45tyhbj"
  );
  /* useForm */
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(addEventValidator),
  });

  /* States */
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => setOpenModal(true);
  const closeModalHandler = () => setOpenModal(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  /* Functions */
  const submitData = (formData) => {
    console.log(formData, "rtgyhjkn");
    dispatch(postAddEventRequest(formData));
  };

  /* useEffect */
  useEffect(() => {
    dispatch(getserviceDataRequest());
    const payload = {
      id: "",
      page: page,
      limit: limit,
    };
    dispatch(getAddEventRequest(payload));
    const delay = 500;
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, delay);
    if (updateSucces == true) {
      const payload = {
        id: "",
        page: page,
        limit: limit,
      };
      dispatch(getAddEventRequest(payload));
    }
    setValue("locations", selectDefault);
    setValue("service_id", selectDefault);
    dispatch(getlocationDataRequest());
    if (postSuccess == true) {
      reset();
      setOpenModal(false);
    }
    if (postSuccess == true) {
      const payload = {
        id: "",
        page: page,
        limit: limit,
      };
      dispatch(getAddEventRequest(payload));
    }
    if (postSuccess == true) {
      reset();
      setOpenModal(false);
    }
    return () => clearTimeout(timeoutId);
  }, [postSuccess, updateSucces]);

  return (
    <>
     
      <div className="text-end mx-3 mt-3">
        <Button onClick={openModalHandler}>Add Event</Button>
      </div>
    
      <Card className="mt-3 mx-3">
        <CardBody>
          <div className="m-2 table-responsive">
            <Table bordered>
              <thead>
                <tr c>
                  <th>SERVICE NAME</th>
                  <th>DATE</th>
                  <th>DESCRIPTION</th>
                  <th>PHONE NO</th>
                  <th>LOCATION</th>
                  <th>STATUS</th>
                  <th>ACTION</th>
                </tr>
              </thead>

              <tbody>
                {!isLoading && (
                  <>
                    {getEventData?.map((ele, ind) => {
                      return (
                        <tr>
                          <td>{ele.service.name}</td>
                          <td>{dayjs(ele.date).format("DD/MM/YYYY")}</td>
                          <td>{ele.description}</td>
                          <td>{ele.phone_no}</td>
                          <td>{ele.location?.name}</td>
                          <td>{actionData[ele.status]}</td>
                          <td className="text-center">
                            {ele?.status != 2 && ele?.status != 4 ? (
                              <MdCancel
                                color="red"
                                size={20}
                                onClick={() => {
                                  const payload = {
                                    query: ele.id,
                                    status: 4,
                                  };
                                  dispatch(postUpdateEventRequest(payload));
                                }}
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      {isLoading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "300px" }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
      <Modal show={openModal} onHide={closeModalHandler} size="MD">
        <Form onSubmit={handleSubmit(submitData)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="service_id">
              <Form.Label>Service </Form.Label>
              <Form.Control
                as="select"
                placeholder="Enter service "
                {...register("service_id")}
              >
                <option disabled value={selectDefault}>
                  SELECT
                </option>
                {getServiceDate?.map((ele, ind) => {
                  return <option value={ele.id}>{ele?.name}</option>;
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="phone_no">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                {...register("phone_no")}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="date">
              <Form.Label>Event Date</Form.Label>

              <Form.Control
                type="date"
                placeholder="Date of Birth"
                {...register("date")}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter description"
                {...register("description")}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="secondary_phone">
              <Form.Label>Secondary Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter secondary phone"
                {...register("secondary_phone")}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="locations">
              <Form.Label>Locations</Form.Label>
              <Form.Control
                as="select"
                placeholder="Enter locations"
                {...register("locations")}
              >
                <option disabled value={selectDefault}>
                  {" "}
                  SELECT
                </option>
                {getLocData?.map((ele, ind) => {
                  return <option value={ele.id}>{ele.name}</option>;
                })}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModalHandler}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default Events;
