import { message } from "../../utils/message";
import { UserActionTypes, userInitialState } from "./user.model";
import _ from "underscore";

const reducer = (state = userInitialState, action) => {
  switch (action.type) {
    case UserActionTypes.LOGIN_USER_REQUEST:
      return {
        ...state,
        signinisLoading: true,
        userMessage: "",
      };

    case UserActionTypes.LOGIN_USER_SUCCESS:
      var { data } = action?.payload;
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("authKey", data?.datas?.accessToken);
      localStorage.setItem("role", data?.datas?.role);
      return {
        ...state,
        signinisLoading: false,
        signinUser: data?.datas,
        roleRed: data?.datas?.role,
        userMessage: message.loginSuccess,
      };

    case UserActionTypes.LOGIN_USER_FAILURE:
      return {
        ...state,
        signinisLoading: false,
        userMessage: "",
      };

    case UserActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };

    case UserActionTypes.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };

    case UserActionTypes.GET_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stats: action.payload,
      };

    case UserActionTypes.PROFILEUPDATEPOST_REQUEST:
      return {
        ...state,
        profilepostLoad: true,
        updateProfile: false,
      };
    case UserActionTypes.PROFILEUPDATEPOST_SUCCESS:
      return {
        ...state,
        profilepostLoad: false,
        updateProfile: true,
      };
    case UserActionTypes.PROFILEUPDATEPOST_FAILURE:
      return {
        ...state,
        updateProfile: false,
        profilepostLoad: false,
      };

    case UserActionTypes.PROFILEUPDATEGET_REQUEST:
      return {
        ...state,
        profilegetLoad: true,
      };
    case UserActionTypes.PROFILEUPDATEGET_SUCCESS:
      return {
        ...state,
        profilegetLoad: false,
        getProfile: action.payload?.data?.data,
      };
    case UserActionTypes.PROFILEUPDATEGET_FAILURE:
      return {
        ...state,
        profilegetLoad: false,
      };
    case UserActionTypes.SIGNUP_USER_REQUEST:
      return {
        ...state,
        signUpLoad: true,
      };
    case UserActionTypes.SIGNUP_USER_SUCCESS:
      return {
        ...state,
        signUpLoad: false,
        signupStatus: true,
      };
    case UserActionTypes.SIGNUP_USER_FAILURE:
      return {
        ...state,
        signUpLoad: false,
      };
    case UserActionTypes.CLEARMESSAGES:
      return {
        ...state,
        updateProfile: false,
        userMessage: "",
      };
    default:
      return state;
  }
};

export { reducer as UserReducer };
