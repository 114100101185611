import { useState, useEffect } from 'react';
import { DIMENSION, LAYOUT, MENU_BEHAVIOUR, NAV_COLOR, MENU_PLACEMENT, RADIUS, THEME_COLOR, USER_ROLE } from './Constants.js';

export const IS_DEMO = true;
export const IS_AUTH_GUARD_ACTIVE = true;
export const SERVICE_URL = '/app';
export const USE_MULTI_LANGUAGE = true;

export const REACT_HELMET_PROPS = {
  defaultTitle: 'Social App',
  titleTemplate: '%s | Social App',
};

export const DEFAULT_PATHS = {
  APP: '/',
  LOGIN: '/signin',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  USER_WELCOME: '/dashboards/default',
  NOTFOUND: '/page-not-found',
  UNAUTHORIZED: '/unauthorized',
  INVALID_ACCESS: '/invalid-access',
};

export const DEFAULT_SETTINGS = {
  MENU_PLACEMENT: MENU_PLACEMENT.Vertical,
  MENU_BEHAVIOUR: MENU_BEHAVIOUR.Unpinned,
  LAYOUT: LAYOUT.Fluid,
  RADIUS: RADIUS.Rounded,
  COLOR: THEME_COLOR.DarkGreen,
  NAV_COLOR: NAV_COLOR.Default,
  USE_SIDEBAR: false,
};


export const REDUX_PERSIST_KEY = 'classic-dashboard';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    let resizeTimer = -1;
    function handleResize() {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      }, 50);
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      clearTimeout(resizeTimer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return windowSize;
};


export const checkPlacement = ({ placement, breakpoints }) => {
  const windowWidth = window.innerWidth;
  if (placement === MENU_PLACEMENT.Horizontal) {
    // Adding vertical menu for mobile
    if (breakpoints.horizontalMobile > windowWidth) {
      return {
        status: 1,
        placementHtmlData: MENU_PLACEMENT.Horizontal,
        dimensionHtmlData: DIMENSION.Mobile,
        view: MENU_PLACEMENT.Vertical,
      };
    }
    // Adding horizontal menu for desktop
    return {
      status: 2,
      placementHtmlData: MENU_PLACEMENT.Horizontal,
      dimensionHtmlData: DIMENSION.Desktop,
      view: MENU_PLACEMENT.Horizontal,
    };
  }

  if (placement === MENU_PLACEMENT.Vertical) {
    // Adding vertical menu for mobile
    if (breakpoints.verticalMobile > windowWidth) {
      return {
        status: 3,
        placementHtmlData: MENU_PLACEMENT.Horizontal,
        dimensionHtmlData: DIMENSION.Mobile,
        view: MENU_PLACEMENT.Vertical,
      };
    }
    // Adding vertical menu for desktop
    return {
      status: 4,
      placementHtmlData: MENU_PLACEMENT.Vertical,
      dimensionHtmlData: DIMENSION.Desktop,
      view: MENU_PLACEMENT.Vertical,
    };
  }
  return { status: 0, placementHtmlData: '', dimensionHtmlData: '', view: '' };
};

// Decides which type of menu behaviour to init based on the parameters or the current window size.
//  behaviourStatus:
//  1 {selected: 'unpinned', placement: 'vertical',   dimension: 'mobile|desktop',  html-data: 'unpinned'}
//  2 {selected: 'unpinned', placement: 'vertical',   dimension: 'tablet',          html-data: 'unpinned'}
//  3 {selected: 'pinned',   placement: 'vertical',   dimension: 'mobile|desktop',  html-data: 'pinned'}
//  4 {selected: 'pinned',   placement: 'vertical',   dimension: 'tablet',          html-data: 'unpinned'}
//  5 {selected: 'unpinned', placement: 'horizontal', dimension: 'all',             html-data: 'unpinned'}
//  6 {selected: 'pinned',   placement: 'horizontal', dimension: 'all',             html-data: 'pinned'}
export const checkBehaviour = ({ placement, behaviour, breakpoints }) => {
  const windowWidth = window.innerWidth;
  // Vertical rules
  if (placement === MENU_PLACEMENT.Vertical && behaviour === MENU_BEHAVIOUR.Unpinned) {
    // Mobile and Desktop
    if (breakpoints.verticalMobile > windowWidth || breakpoints.verticalUnpinned <= windowWidth) {
      return {
        status: 1,
        // A small fix to make sure nav config at Vertical No Semi Hidden overrides theme settings.
        behaviourHtmlData: breakpoints.verticalUnpinned !== breakpoints.verticalMobile ? MENU_BEHAVIOUR.Unpinned : MENU_BEHAVIOUR.Pinned,
      };
    }
    // Tablet
    return {
      status: 2,
      behaviourHtmlData: MENU_BEHAVIOUR.Unpinned,
    };
  }
  if (placement === MENU_PLACEMENT.Vertical && behaviour === MENU_BEHAVIOUR.Pinned) {
    // Mobile and Desktop
    if (breakpoints.verticalMobile > windowWidth || breakpoints.verticalUnpinned <= windowWidth) {
      return {
        status: 3,
        behaviourHtmlData: MENU_BEHAVIOUR.Pinned,
      };
    }
    // Tablet
    return {
      status: 4,
      behaviourHtmlData: MENU_BEHAVIOUR.Unpinned,
    };
  }

  // Horizontal rules
  if (placement === MENU_PLACEMENT.Horizontal && behaviour === MENU_BEHAVIOUR.Unpinned) {
    return {
      status: 5,
      behaviourHtmlData: MENU_BEHAVIOUR.Unpinned,
    };
  }
  if (placement === MENU_PLACEMENT.Horizontal && behaviour === MENU_BEHAVIOUR.Pinned) {
    return {
      status: 6,
      behaviourHtmlData: MENU_BEHAVIOUR.Pinned,
    };
  }
  return { status: 0, behaviourHtmlData: '' };
};

export const isDeeplyDiffPlacementStatus = (a, b) => {
  if (a.status !== b.status) return true;
  if (a.placementHtmlData !== b.placementHtmlData) return true;
  if (a.dimensionHtmlData !== b.dimensionHtmlData) return true;
  // noinspection RedundantIfStatementJS
  if (a.view !== b.view) return true;
  return false;
};
export const isDeeplyDiffBehaviourStatus = (a, b) => {
  if (a.status !== b.status) return true;
  // noinspection RedundantIfStatementJS
  if (a.behaviourHtmlData !== b.behaviourHtmlData) return true;
  return false;
};
