import { AuthActionTypes, authInitialState } from "./auth.model";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

const reducer = (state = authInitialState, action) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case AuthActionTypes.LOGIN_USER_SUCCESS:
      var { data } = action?.payload;
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("authKey", data?.datas?.accessToken);
      return {
        ...state,
        isLoading: false,
        auth: data?.datas,
      };

    case AuthActionTypes.LOGIN_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export { reducer as AuthReducer };
