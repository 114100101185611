export const AuthActionTypes = {
    LOGIN_USER_REQUEST: "@@user/LOGIN_USER_REQUEST",
    LOGIN_USER_SUCCESS: "@@user/LOGIN_USER_SUCCESS",
    LOGIN_USER_FAILURE: "@@user/LOGIN_USER_FAILURE",
}

export const authInitialState = {
    user: {},
    isLoading: false,
}

export const signInSelector = (state) => state.AuthReducer;
